import { useEffect, useState } from 'react';
import MinimalCartaPropiedad from '../../components/CartaPropiedad/MinimalCartaPropiedad';
import { apiCall } from '../../shared/service';
import { IPropiedad } from '../../shared/types';
import startCase from 'lodash/startCase';
import { Skeleton } from '@mui/material';
import GalponIcon from '../../assets/icons/galpon.png';
import ViviendaIcon from '../../assets/icons/casa.png';
import LocalIcon from '../../assets/icons/locales.png';
import Terreno from '../../assets/icons/terreno.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import './Body.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const categories = {
  Galpones: ['Galpon'],
  localesYOficinas: ['Local', 'Oficina'],
  Terrenos: ['Terreno'],
  viviendas: ['Vivienda'],
};

const categorias = [
  { categoria: 'Todos', url: '/propiedades?amount=10' },
  { categoria: 'Galpones', url: '/propiedades?amount=10&tipo=Galpon' },
  { categoria: 'Locales', url: '/propiedades?amount=10&tipo=Local' },
  { categoria: 'Terrenos', url: '/propiedades?amount=10&tipo=Terreno' },
  { categoria: 'Viviendas', url: '/propiedades?amount=10&tipo=Vivienda' },
];

const Body = () => {
  const [propiedades, setPropiedades] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchPropiedades = async () => {
    setLoading(true);
    apiCall(`/propiedad?amount=5&activo=true&tipo=Vivienda`, 'GET').then((res: any) => {
      setPropiedades((estadoAnterior) => estadoAnterior.concat(res.propiedades));
      setLoading(false);
    });
    apiCall(`/propiedad?amount=5&activo=true&tipo=Local,Oficina`, 'GET').then((res: any) => {
      setPropiedades((estadoAnterior) => estadoAnterior.concat(res.propiedades));
      setLoading(false);
    });
    apiCall(`/propiedad?amount=5&activo=true&tipo=Galpon`, 'GET').then((res: any) => {
      setPropiedades((estadoAnterior) => estadoAnterior.concat(res.propiedades));
      setLoading(false);
    });
    apiCall(`/propiedad?amount=5&activo=true&tipo=Terreno`, 'GET').then((res: any) => {
      setPropiedades((estadoAnterior) => estadoAnterior.concat(res.propiedades));
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchPropiedades();
  }, []);

  return (
    <div className="body-main">
      <section className="container-categories">
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          direction="horizontal"
          breakpoints={{
            0: {
              slidesPerView: 2,
            },
            320: {
              slidesPerView: 3,
            },
            576: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 5,
            },
            992: {
              slidesPerView: 5,
            },
            1024: {
              slidesPerView: 5,
            },
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {categorias.map((e) => {
            return (
              <SwiperSlide>
                <a className="link-categorie" href={e.url}>
                  {e.categoria}
                </a>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>
      <div className="categories">
        {Object.keys(categories).map((key) => {
          const Icon = () => {
            if (key === 'Galpones') {
              return <img width={25} height={25} src={GalponIcon} alt={key} />;
            } else if (key === 'viviendas') {
              return <img width={25} height={25} src={ViviendaIcon} alt={key} />;
            } else if (key === 'localesYOficinas') {
              return <img width={25} height={25} src={LocalIcon} alt={key} />;
            } else if (key === 'Terrenos') {
              return <img width={25} height={25} src={Terreno} alt={key} />;
            } else {
              return null;
            }
          };
          return (
            <div className="category" key={key}>
              <div className="container-title">
                <Icon />
                <h3 className="cat-title">{startCase(key)}</h3>
                <a href={`/propiedades?amount=10&tipo=${categories[key][0]}`} style={{ margin: '20px 0 15px 15px', color: '#48e' }}>
                  Ver todos
                </a>
              </div>
              <div className="category-row">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={25}
                  direction="horizontal"
                  navigation={true}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    390: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    576: {
                      slidesPerView: 2,
                      spaceBetween: 15,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    992: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                >
                  {loading
                    ? [...Array(4)].map((x, idx) => (
                        <div key={`skeleton${idx}`} className="minimal-card-skeleton">
                          <Skeleton variant="text" width="100%" height={20} />
                          <Skeleton variant="text" width="80%" height={20} />
                          <Skeleton variant="rectangular" width="100%" height="60%" />
                        </div>
                      ))
                    : propiedades
                        ?.filter((propiedad: IPropiedad) => categories[key].includes(propiedad.tipo))
                        .slice(0, 4)
                        .map((propiedad: IPropiedad) => (
                          <SwiperSlide className="swiper-props">
                            <MinimalCartaPropiedad key={propiedad.id} {...propiedad} />
                          </SwiperSlide>
                        ))}
                </Swiper>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Body;
