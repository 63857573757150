/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Badge } from 'react-bootstrap';
import { Card, Dialog, IconButton, MenuItem, Select, SelectProps, Skeleton } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { apiCall } from '../../shared/service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import startCase from 'lodash/startCase';
import './SearchPage.scss';
import CardGrid from '../../components/CardGrid/CardGrid';
import PaginationComponent from '../../components/CardGrid/PaginationComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faSliders, faXmark } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import SearchIcon from '../../assets/search-line-blue.svg';
import axios from 'axios';

interface RefProp {
  id: string | number;
  res?: number;
}

interface CustomSelectProps extends SelectProps<any> {
  defaultValue?: any;
}

const SearchPage = () => {
  const [propiedades, setPropiedades] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [propiedadesPerPage, setPropiedadesPerPage] = useState(10);
  const [searchParams] = useSearchParams();
  const [type, setType] = useState<string | null>(null);
  const [count, setCount] = useState(0);
  const [groups, setGroups] = useState();
  const [query, setQuery] = useState('');
  const [operacionFilter, setOperacion] = useState<string[]>([]);
  const [tipoFilter, setTipo] = useState<string[]>([]);
  const [subTipoFilter, setSubTipo] = useState<string[]>([]);
  const [ambientesFilter, setAmbientes] = useState<string[]>([]);
  const [barrioFilter, setBarrio] = useState<string[]>([]);
  const [barrios, setBarrios] = useState<{ barrio: string }[]>([]);
  const [priceRange, setPriceRange] = useState<string[]>([]);
  const [priceInput, setPriceInput] = useState<string[]>([]);
  const [supRange, setSupRange] = useState<string[]>([]);
  const [supInput, setSupInput] = useState<string[]>(['0', '5000']);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [filter, setFilter] = useState<boolean>(false);
  const [idPropiedad, setIdPropiedad] = useState<number | undefined>();
  const [resProp, setResProp] = useState({
    id: 0,
    res: 0,
  });

  const handleCheckboxChange = (key) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key], // Invierte el estado del checkbox correspondiente al key
    }));
  };

  const handleKeyFilter = (key: string, value: any) => {
    if (key === 'tipo') {
      if (tipoFilter.includes(value)) {
        setTipo(tipoFilter.filter((tipo) => tipo !== value));
      } else {
        setTipo([...tipoFilter, value]);
      }
    } else if (key === 'subtipo') {
      if (subTipoFilter.includes(value) || subTipoFilter.includes('fondo')) {
        if (value === 'Fondo de comercio') {
          setSubTipo(subTipoFilter.filter((subtipo) => subtipo !== 'fondo'));
        } else {
          setSubTipo(subTipoFilter.filter((subtipo) => subtipo !== value));
        }
      } else {
        if (value === 'Fondo de comercio') {
          setSubTipo([...subTipoFilter, 'fondo']);
        } else {
          setSubTipo([...subTipoFilter, value]);
        }
      }
    } else if (key === 'operacion') {
      if (operacionFilter.includes(value)) {
        setOperacion(operacionFilter.filter((operacion) => operacion !== value));
      } else {
        setOperacion([...operacionFilter, value]);
      }
    } else if (key === 'ambientes') {
      if (ambientesFilter.includes(value)) {
        setAmbientes(ambientesFilter.filter((ambientes) => ambientes !== value));
      } else {
        setAmbientes([...ambientesFilter, value]);
      }
    } else if (key === 'barrio') {
      setBarrio([value.target.value] as any);
    } else if (key === 'precio') {
      setPriceRange(value as any);
    } else if (key === 'superficie') {
      setSupRange(value as any);
    }
  };

  const customProps: CustomSelectProps = {
    defaultValue: barrioFilter,
  };

  const history = useNavigate();

  const searchQuery = useMemo(() => {
    let search = '';

    for (const entry of searchParams.entries()) {
      search += `${entry[0]}=${entry[1]}&`;
      if (entry[0] === 'tipo') {
        setTipo(entry[1].split(','));
      }
      if (entry[0] === 'subtipo') {
        setSubTipo(entry[1].split(','));
      }
      if (entry[0] === 'operacion') {
        setOperacion(entry[1].split(','));
      }
      if (entry[0] === 'ambientes') {
        setAmbientes(entry[1].split(','));
      }
      if (entry[0] === 'barrio') {
        setBarrio(entry[1].split(','));
      }
      if (entry[0] === 'precio') {
        setPriceRange(entry[1].split(','));
        setPriceInput(entry[1].split(','));
      }
      if (entry[0] === 'superficie') {
        setSupRange(entry[1].split(','));
        setSupInput(entry[1].split(','));
      }
    }
    return search;
  }, [searchParams]);

  const fetchPropiedades = useCallback(async () => {
    setLoading(true);
    apiCall(`/propiedad?amount=${propiedadesPerPage * currentPage}&${searchQuery}`, 'GET').then((res: any) => {
      setPropiedades(res.propiedades);
      setCount(res.count);
      setGroups(res.groups);
      setLoading(false);
    });
  }, [searchQuery, currentPage, propiedadesPerPage]);

  useEffect(() => {
    fetchPropiedades();
    apiCall(`/propiedad/barrios`, 'GET').then((res: any) => {
      setBarrios(res);
    });
    for (const entry of searchParams.entries()) {
      setType(entry[1]);
    }
  }, [query]);

  useEffect(() => {
    let query = `?amount=${propiedadesPerPage * currentPage}`;

    if (tipoFilter?.length) {
      query += `&tipo=${tipoFilter.join(',')}`;
    }
    if (subTipoFilter?.length) {
      query += `&subtipo=${subTipoFilter.join(',')}`;
    }
    if (operacionFilter?.length) {
      query += `&operacion=${operacionFilter.join(',')}`;
    }
    if (ambientesFilter?.length) {
      query += `&ambientes=${ambientesFilter.join(',')}`;
    }
    if (barrioFilter?.length) {
      query += `&barrio=${barrioFilter.join(',')}`;
    }
    if (priceRange?.length) {
      query += `&precio=${priceRange.map((x) => (!x ? 0 : x)).join(',')}`;
    }
    if (supRange?.length) {
      query += `&superficie=${supRange.map((x) => (!x ? 0 : x)).join(',')}`;
    }
    setQuery(query);
    history(`/propiedades${query}`);
  }, [currentPage, propiedadesPerPage, operacionFilter, tipoFilter, subTipoFilter, ambientesFilter, barrioFilter, priceRange, supRange]);

  const handleDeleteFilter = (value: string) => {
    if (tipoFilter.includes(value)) {
      setTipo(tipoFilter.filter((tipo) => tipo !== value));
    }
    if (subTipoFilter.includes(value)) {
      if (value === 'Fondo de comercio') {
        setSubTipo(subTipoFilter.filter((subtipo) => subtipo !== 'fondo'));
      } else {
        setSubTipo(subTipoFilter.filter((subtipo) => subtipo !== value));
      }
    }
    if (operacionFilter.includes(value)) {
      setOperacion(operacionFilter.filter((operacion) => operacion !== value));
    }
    if (ambientesFilter.includes(value)) {
      setAmbientes(ambientesFilter.filter((ambientes) => ambientes !== value));
    }
    if (barrioFilter.includes(value)) {
      setBarrio(barrioFilter.filter((barrio) => barrio !== value));
    }
  };

  const { tipos, operacion, ambientes, subtipos } = useMemo(() => {
    if (groups) {
      const curatedGroups = {
        tipos: {},
        subtipos: {},
        operacion: {},
        ambientes: {},
      };
      Object.keys(groups).forEach((key) => {
        const group = {};
        (groups as any)[key]?.forEach((item) => {
          const { subtipo, subtipo_local, amount } = item;
          let displayName = '';

          const otherKey = Object.keys(item).filter((x) => x !== 'amount')[0];
          group[item[otherKey]] = amount;

          if (!subtipo_local && subtipo) {
            group[subtipo] = amount;
          }
          if (!subtipo && subtipo_local) {
            if (subtipo_local === 'fondo') {
              displayName = 'Fondo de comercio';
            } else {
              displayName = subtipo || subtipo_local;
            }
            group[displayName] = amount;
          }
        });
        curatedGroups[key] = group;
      });
      return curatedGroups;
    }
    return { tipos: {}, operacion: {}, ambientes: {}, subtipos: {} };
  }, [groups]);

  const filterBadge = (val, key, prefix, onDelete) => {
    if (val === '0') {
      return null;
    }
    const Amb = () => {
      if (val === '1') {
        return prefix + val + ' ambiente';
      } else if (val === '2') {
        return prefix + val + ' ambientes';
      } else if (val === '3') {
        return prefix + val + ' ambientes';
      } else if (val === '4') {
        return prefix + val + ' ambientes';
      } else if (val === '5') {
        return prefix + val + ' ambientes';
      } else if (val === '6') {
        return prefix + val + ' ambientes';
      } else {
        return prefix + val;
      }
    };

    return (
      <Badge
        style={{
          margin: '0 5px 10px 0px',
          padding: '6px 10px',
          borderRadius: '4px !important',
          backgroundColor: '#48e !important',
        }}
        key={val + key}
      >
        <Amb />
        <FontAwesomeIcon
          style={{
            marginLeft: '5px',
            cursor: 'pointer',
          }}
          onClick={() => (onDelete ? onDelete() : handleDeleteFilter(val))}
          icon={faCircleXmark as IconProp}
        />
      </Badge>
    );
  };

  const resPropRef = useRef<RefProp>(resProp);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueId = parseInt(e.target.value);
    setIdPropiedad(valueId);
  };

  useEffect(() => {
    const FetchId = async () => {
      try {
        const id = idPropiedad;
        const res = await axios.get(`/propiedad/${id}`);
        const data = await res.data;
        if (id === data.id) {
          setResProp(data.id);
          resPropRef.current = {
            id: 'Existe',
            res: data.id,
          };
        } else {
          resPropRef.current = {
            id: 'No existe',
          };
        }
      } catch (err) {
        resPropRef.current = {
          id: 'No existe',
        };
        console.log(err);
      }
    };
    FetchId();
  }, [idPropiedad]);

  const buscarId = useCallback(async () => {
    if (resPropRef.current.id === 'Existe') {
      history(`/propiedades/${resPropRef.current.res}`);
    } else {
      alert('No existe el id especificado');
    }
  }, [resPropRef]);

  const SearchForm = useMemo(
    () => (
      <Card className="filtros-card">
        {loading ? (
          <div>
            <Skeleton variant="text" width={'100%'} height={20} />
            <Skeleton variant="text" width={'100%'} height={20} />
            <Skeleton variant="text" width={'100%'} height={20} />
            <Skeleton variant="text" width={'100%'} height={20} />
            <Skeleton variant="text" width={'100%'} height={20} />
          </div>
        ) : (
          <section className="container-filter">
            <div className="filtro-category">
              <h5>Filtros</h5>
              {[...tipoFilter, ...operacionFilter, ...ambientesFilter, ...barrioFilter].map((tipo: string, index) =>
                filterBadge(tipo, index, '', null),
              )}
              {priceRange.map((tipo: string, index) =>
                filterBadge(
                  tipo,
                  index,
                  index === 0 ? 'Desde $' : 'Hasta $',
                  index === 0 ? () => setPriceRange(['0', priceRange[1]]) : () => setPriceRange([priceRange[0], '0']),
                ),
              )}
              {supRange.map((tipo: string, index) =>
                filterBadge(
                  tipo,
                  index,
                  index === 0 ? 'Desde m2 ' : 'Hasta m2 ',
                  index === 0 ? () => setSupRange(['0', supRange[1]]) : () => setSupRange([supRange[0], '0']),
                ),
              )}
            </div>
            <div className="filtro-category">
              <h5>Barrios</h5>
              <div className="category-options" style={{ marginBottom: '5px' }}>
                <Select {...customProps} onChange={(val: any) => handleKeyFilter('barrio', val)}>
                  {barrios &&
                    barrios.map((x) => {
                      return <MenuItem value={x.barrio}>{x !== null && x.barrio !== '' && x.barrio}</MenuItem>;
                    })}
                </Select>
              </div>
            </div>
            <div className="filtro-category">
              <h5>Tipo de propiedad</h5>
              <div className="category-options">
                {Object.keys(tipos)
                  .sort()
                  .map((key, idx) => {
                    const isChecked = checkboxStates[key] || false;
                    return (
                      <div
                        onClick={() => {
                          handleKeyFilter('tipo', key);
                          handleCheckboxChange(key);
                        }}
                        className={tipoFilter.includes(key) ? 'category-item cat-disabled' : 'category-item'}
                        key={key + idx}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                          <div>
                            {startCase(key)}&nbsp;<span className="text-gray">( {tipos[key]} )</span>
                          </div>
                          <input checked={isChecked} type="checkbox" />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="filtro-category">
              <h5>SubTipo de propiedad</h5>
              <div className="category-options">
                {Object.keys(subtipos)
                  .sort()
                  .map((key, idx) => {
                    if (key === 'null' || !type) {
                      return null; // Omitir la key si es null
                    } else if ((type === 'Local' || type === 'Galpon') && key !== 'Fondo de comercio') {
                      return null; // Omitir la key si no es Local ni Galpon y es 'Fondo'
                    } else if (type === 'Vivienda' && !(key === 'ph' || key === 'quinta' || key === 'departamento' || key === 'casa')) {
                      return null; // Omitir la key si es Vivienda y no es ph, quinta, departamento o casa
                    } else if (type === '10') {
                      return null;
                    }
                    const isChecked = checkboxStates[key] || false;

                    return (
                      <div
                        onClick={() => {
                          handleKeyFilter('subtipo', key);
                          handleCheckboxChange(key);
                        }}
                        className={subTipoFilter.includes(key) ? 'category-item cat-disabled' : 'category-item'}
                        key={key + idx}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                          <div>
                            {startCase(key)}&nbsp;<span className="text-gray">({subtipos[key]} )</span>
                          </div>
                          <input checked={isChecked} type="checkbox" />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="filtro-category">
              <h5>Tipo de operación</h5>
              <div className="category-options">
                {Object.keys(operacion)
                  .sort()
                  .map((key, idx) => {
                    const isChecked = checkboxStates[key] || false;
                    return (
                      <div
                        onClick={() => {
                          handleKeyFilter('operacion', key);
                          handleCheckboxChange(key);
                        }}
                        className={tipoFilter.includes(key) ? 'category-item cat-disabled' : 'category-item'}
                        key={key + idx}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                          <div>
                            {startCase(key)}&nbsp;<span className="text-gray">( {operacion[key]} )</span>
                          </div>
                          <input checked={isChecked} type="checkbox" />
                        </div>
                        {/* <span>( {tipos[key]} )</span> */}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="filtro-category">
              <h5>Ambientes</h5>
              <div className="category-options ambientes-container">
                {Object.keys(ambientes)
                  .sort((a, b) => {
                    return parseInt(a) - parseInt(b);
                  })
                  .filter((x) => x !== 'null')
                  .map((key, idx) => {
                    return (
                      <span
                        onClick={() => handleKeyFilter('ambientes', key)}
                        className={ambientesFilter.includes(key) ? 'category-item cat-inactive ambients' : 'ambients'}
                        key={key + idx}
                      >
                        <p>{startCase(key)}</p>
                        {/* <span>( {ambientes[key]} )</span> */}
                      </span>
                    );
                  })}
              </div>
            </div>
            {/* <div className="filtro-category">
              <h5>Precio $</h5>
              <div className="category-options">
                <div
                  style={{
                    display: 'flex',
                    marginBottom: '10px',
                  }}
                >
                  <Input
                    style={{
                      marginRight: '20px',
                      color: '#48e',
                    }}
                    color="primary"
                    type="range"
                    placeholder="Desde"
                    onChange={(e) => setPriceInput([e.target.value, priceInput[1]])}
                    value={priceInput[0]}
                    defaultValue="0"
                    onBlur={(e) => setPriceRange(priceInput)}
                  />
                  <Input
                    type="range"
                    placeholder="Hasta"
                    onChange={(e) => setPriceInput([priceInput[0], e.target.value])}
                    value={priceInput[1]}
                    defaultValue="0"
                    onBlur={(e) => setPriceRange(priceInput)}
                  />
                </div>
              </div>
            </div> */}
            <div className="filtro-category">
              <h5>
                Superficie total m<sup>2</sup>
              </h5>
              <div className="category-options">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    gap: 10,
                    marginBottom: '10px',
                  }}
                >
                  <div>
                    <input
                      className="form-range"
                      id="min"
                      style={{
                        marginRight: '20px',
                      }}
                      type="range"
                      placeholder="Desde"
                      onChange={(e) => setSupInput([e.target.value, supRange[1]])}
                      value={supInput[0]}
                      defaultValue={0}
                      min={0}
                      max={2000}
                      step={100}
                      onBlur={(e) => setSupRange(supInput)}
                    />
                    <label htmlFor="min">
                      <b>min</b> {supInput[0] ? supInput[0] : 0} m2
                    </label>
                  </div>
                  <div>
                    <input
                      id="max"
                      className="form-range"
                      type="range"
                      placeholder="Hasta"
                      min={0}
                      max={5000}
                      step={100}
                      onChange={(e) => setSupInput([supRange[0], e.target.value])}
                      value={supInput[1]}
                      defaultValue={5000}
                      onBlur={(e) => setSupRange(supInput)}
                    />
                    <label htmlFor="max">
                      <b>max</b> {supInput[1] ? supInput[1] : 5000} m2
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="filtro-category">
              <h5>Busqueda por ID</h5>
              <div style={{ margin: '10px 0' }}>
                <div className="category-options search-container" style={{ position: 'relative' }}>
                  <input onChange={handleChange} className="search-id" type="search" placeholder="Busqueda por ID" />
                  <img
                    onClick={buscarId}
                    src={SearchIcon}
                    width={28}
                    alt="search"
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '25%',
                      appearance: 'none',
                      cursor: 'pointer',
                      border: 'none',
                      background: 'inherit',
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
      </Card>
    ),
    [
      tipos,
      operacion,
      loading,
      tipoFilter,
      subTipoFilter,
      operacionFilter,
      ambientesFilter,
      barrioFilter,
      barrios,
      priceInput,
      supInput,
      priceRange,
      supRange,
    ],
  );

  const handleClose = () => {
    setFilter(false);
  };

  const FilterOpen = () => {
    return (
      <div onClick={() => setFilter(true)} className="button-filter">
        <FontAwesomeIcon icon={faSliders as IconProp} />
        <p style={{ margin: 0 }}>Filtros</p>
      </div>
    );
  };

  const Filtros = () => {
    return (
      <section>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 10,
            width: '90%',
            margin: '5px auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 10,
            }}
          >
            <FontAwesomeIcon color="#48e" icon={faSliders as IconProp} />
            <p style={{ fontWeight: 600, fontSize: '20px', margin: 0 }}>Filtrar por</p>
          </div>
          <IconButton onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark as IconProp} />
          </IconButton>
        </div>
        <section
          className="container-filter"
          style={{
            margin: '10px 20px',
            padding: '8px',
            borderRadius: 10,
            paddingLeft: '10px',
            zIndex: '99999 !important',
            backgroundColor: '#F5F5F5',
          }}
        >
          <div className="filtro-category">
            <h5 style={{ fontWeight: 600 }}>Barrios</h5>
            <div className="category-options" style={{ marginBottom: '5px' }}>
              <Select {...customProps} onChange={(val: any) => handleKeyFilter('barrio', val)}>
                {barrios &&
                  barrios.map((x) => {
                    return <MenuItem value={x.barrio}>{x !== null && x.barrio !== '' && x.barrio}</MenuItem>;
                  })}
              </Select>
            </div>
          </div>
          <div className="filtro-category">
            <h5 style={{ fontWeight: 600 }}>Filtros</h5>
            {[...tipoFilter, ...operacionFilter, ...ambientesFilter, ...barrioFilter].map((tipo: string, index) =>
              filterBadge(tipo, index, '', null),
            )}
            {priceRange.map((tipo: string, index) =>
              filterBadge(
                tipo,
                index,
                index === 0 ? 'Desde $' : 'Hasta $',
                index === 0 ? () => setPriceRange(['0', priceRange[1]]) : () => setPriceRange([priceRange[0], '0']),
              ),
            )}
            {supRange.map((tipo: string, index) =>
              filterBadge(
                tipo,
                index,
                index === 0 ? 'Desde m2 ' : 'Hasta m2 ',
                index === 0 ? () => setSupRange(['0', supRange[1]]) : () => setSupRange([supRange[0], '0']),
              ),
            )}
          </div>
          <div className="filtro-category">
            <h5 style={{ fontWeight: 600 }}>Tipo de propiedad</h5>
            <div className="category-options" style={{ marginRight: '15px' }}>
              {Object.keys(tipos)
                .sort()
                .map((key, idx) => {
                  const isChecked = checkboxStates[key] || false;
                  return (
                    <div
                      onClick={() => {
                        handleKeyFilter('tipo', key);
                        handleCheckboxChange(key);
                      }}
                      className={tipoFilter.includes(key) ? 'category-item cat-disabled' : 'category-item'}
                      key={key + idx}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <div>
                          {startCase(key)}&nbsp;<span>( {tipos[key]} )</span>
                        </div>
                        <input className="inputs" checked={isChecked} type="checkbox" />
                      </div>
                      {/* <span>( {tipos[key]} )</span> */}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="filtro-category">
            <h5>SubTipo de propiedad</h5>
            <div className="category-options">
              {Object.keys(subtipos)
                .sort()
                .map((key, idx) => {
                  if (key === 'null' || !type) {
                    return null; // Omitir la key si es null
                  } else if ((type === 'Local' || type === 'Galpon') && key !== 'Fondo de comercio') {
                    return null; // Omitir la key si no es Local ni Galpon y es 'Fondo'
                  } else if (type === 'Vivienda' && !(key === 'ph' || key === 'quinta' || key === 'departamento' || key === 'casa')) {
                    return null; // Omitir la key si es Vivienda y no es ph, quinta, departamento o casa
                  } else if (type === '10') {
                    return null;
                  }
                  const isChecked = checkboxStates[key] || false;

                  return (
                    <div
                      onClick={() => {
                        handleKeyFilter('subtipo', key);
                        handleCheckboxChange(key);
                      }}
                      className={subTipoFilter.includes(key) ? 'category-item cat-disabled' : 'category-item'}
                      key={key + idx}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <div>
                          {startCase(key)}&nbsp;<span className="text-gray">({subtipos[key]} )</span>
                        </div>
                        <input checked={isChecked} type="checkbox" />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="filtro-category">
            <h5 style={{ fontWeight: 600 }}>Tipo de operación</h5>
            <div className="category-options" style={{ marginRight: '15px' }}>
              {Object.keys(operacion)
                .sort()
                .map((key, idx) => {
                  const isChecked = checkboxStates[key] || false;
                  return (
                    <div
                      onClick={() => handleKeyFilter('operacion', key)}
                      className={tipoFilter.includes(key) ? 'category-item cat-disabled' : 'category-item'}
                      key={key + idx}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <div>
                          {startCase(key)}&nbsp;<span>( {operacion[key]} )</span>
                        </div>
                        <input className="inputs" checked={isChecked} type="checkbox" onChange={() => handleCheckboxChange(key)} />
                      </div>
                      {/* <span>( {tipos[key]} )</span> */}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="filtro-category ">
            <h5 style={{ fontWeight: 600 }}>Ambientes</h5>
            <div className="category-options ambientes-container">
              {Object.keys(ambientes)
                .sort((a, b) => {
                  return parseInt(a) - parseInt(b);
                })
                .filter((x) => x !== 'null')
                .map((key, idx) => {
                  return (
                    <span
                      onClick={() => handleKeyFilter('ambientes', key)}
                      className={ambientesFilter.includes(key) ? 'category-item cat-inactive ambients' : 'ambients'}
                      key={key + idx}
                    >
                      <p>{startCase(key)}</p>
                      {/* <span>( {ambientes[key]} )</span> */}
                    </span>
                  );
                })}
            </div>
          </div>
          <div className="filtro-category">
            <h5>
              Superficie total m<sup>2</sup>
            </h5>
            <div className="category-options">
              <div
                style={{
                  display: 'flex',
                  marginBottom: '10px',
                }}
              >
                <div>
                  <input
                    className="form-range"
                    id="min"
                    style={{
                      marginRight: '20px',
                    }}
                    type="range"
                    placeholder="Desde"
                    onChange={(e) => setSupInput([e.target.value, supRange[1]])}
                    value={supInput[0]}
                    defaultValue={0}
                    min={0}
                    max={2000}
                    step={100}
                    onBlur={(e) => setSupRange(supInput)}
                  />
                  <label htmlFor="min">{supInput[0] ? supInput[0] : 3000} m2</label>
                </div>
                <div>
                  <input
                    id="max"
                    className="form-range"
                    type="range"
                    placeholder="Hasta"
                    min={0}
                    max={5000}
                    step={100}
                    onChange={(e) => setSupInput([supRange[0], e.target.value])}
                    value={supInput[1]}
                    defaultValue={5000}
                    onBlur={(e) => setSupRange(supInput)}
                  />
                  <label htmlFor="max">{supInput[1] ? supInput[1] : 3000} m2</label>
                </div>
              </div>
            </div>
          </div>
          <div className="filtro-category">
            <h5 style={{ fontWeight: 600 }}>Busqueda por ID</h5>
            <div style={{ margin: '10px 0' }}>
              <div className="category-options search-container" style={{ position: 'relative' }}>
                <input
                  onChange={handleChange}
                  style={{ width: '100%', height: '100%' }}
                  className="search-id"
                  type="search"
                  value={idPropiedad}
                  placeholder="Busqueda por ID"
                />
                <img
                  onClick={buscarId}
                  src={SearchIcon}
                  width={28}
                  alt="search"
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '25%',
                    appearance: 'none',
                    cursor: 'pointer',
                    border: 'none',
                    background: 'inherit',
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  };

  const Titulo = () => {
    if (tipoFilter[0] === 'Galpon') {
      return 'Galpones';
    } else if (tipoFilter[0] === 'Local') {
      return 'Locales';
    } else if (tipoFilter[0] === 'Oficina') {
      return 'Oficinas';
    } else if (tipoFilter[0] === 'Vivienda') {
      return 'Viviendas';
    } else if (tipoFilter[0] === 'Terreno') {
      return 'Terrenos';
    } else {
      return 'Inmuebles';
    }
  };

  return (
    <>
      <Helmet
        title={Titulo() + ' en alquiler y venta' + (!tipoFilter[0] ? ' CABA y Gran. Bs.As' : '')}
        meta={[
          {
            name: `descripcion`,
            content:
              'Seky Propiedades, es una inmobiliaria comercial ubicada en el centro geográfico de la Ciudad de Buenos Aires que ofrece: Inmuebles Industriales: Alquiler y Venta de Depósitos, Galpones y Edificios Industriales ubicados principalmente en la Zona Centro y Sur de la Capital Federal. Inmuebles Industriales: Alquiler y Venta de Depósitos, Galpones y Edificios Industriales ubicados principalmente en la Zona Centro y Sur de la Capital Federal. Inmuebles Residenciales: Alquiler y venta de departamentos en la Ciudad de Buenos Aires. Servicio de Tasaciones judiciales y privadas con el aval de martilleros especializados.',
          },
          {
            name: 'keywords',
            content:
              'Inmobiliaria, Alquiler, Venta, Galpon, Galpones, Industrial, Departamentos Temporales, Departamentos, Inmobiliaria comercial, Buenos Aires, CABA, Capital, Inmuebles Industriales, Depositos, Edificios industriales, Capital federal, Servicio de Tasaciones judiciales, martilleros especializados',
          },
        ]}
      />
      <div className="img-header">
        <div className="background-header"></div>
        <h1 style={{ textAlign: 'center' }}>
          {Titulo().toUpperCase()} EN ALQUILER Y VENTA {!tipoFilter[0] && 'CABA y Gran. Bs.As'}
        </h1>
      </div>
      <div className="search-page container-fluid">
        <Row>
          <Col md={4}>{SearchForm}</Col>
          {filter && (
            <Dialog fullWidth open={filter} onClose={handleClose}>
              <Filtros />
            </Dialog>
          )}
          <FilterOpen />
          <Col sm={12} md={8}>
            {propiedades.length || loading ? (
              <>
                <CardGrid
                  admin={false}
                  shownProp={propiedades}
                  propiedadesPerPage={propiedadesPerPage}
                  currentPage={currentPage}
                  loading={loading}
                  fetchPropiedades={fetchPropiedades}
                />
                <PaginationComponent
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  propiedadesPerPage={propiedadesPerPage}
                  totalPropiedades={count}
                  setPropiedadesPerPage={setPropiedadesPerPage}
                />
              </>
            ) : (
              <div className="no-propiedad">
                En este momento no hay propiedades como la que buscás. <br />
                Probá eliminando alguno de estos filtros.
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SearchPage;
