import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { useMemo } from 'react';

const LoadMap = ({ lat, lng, title }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDCH9i6fhWfvrvLWE-Q-pgZKaK7pZPUS3o',
    libraries: ['places'],
  });

  if (loadError) return <div>Error al cargar el mapa</div>;
  if (!isLoaded) return <div>Cargando...</div>;

  return <Map lat={lat} lng={lng} />;
};

function Map({ lat, lng }) {
  const center = useMemo(() => ({ lat: lat, lng: lng }), []);

  return (
    <GoogleMap id="map" zoom={15} center={center} mapContainerClassName="google-map">
      <Marker position={center} />
    </GoogleMap>
  );
}

export default LoadMap;
