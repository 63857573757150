import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './AdminFilter.scss';
import Typography from '@mui/material/Typography';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Col, Row } from 'react-bootstrap';
import { propiedadOperation, propiedadType } from '../../shared/types';
import { TextField } from '@material-ui/core';
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';

interface CustomSelectProps extends SelectProps<any> {
  handleChange: (val: any) => any;
  defaultValue: any;
}

// Ejemplo de cómo pasar las propiedades al componente Select

const PropiedadFilter = ({ estado, setEstado, setOperacion, operacion, tipo, setTipo, setPrecio, precio, setTitulo, titulo, fecha, setFecha }) => {
  const customProps: CustomSelectProps = {
    handleChange: (val) => setOperacion(val),
    defaultValue: operacion,
  };
  const customProps2: CustomSelectProps = {
    handleChange: (val: any) => setTipo(val),
    defaultValue: tipo,
  };
  const customProps3: CustomSelectProps = {
    handleChange: (val: any) => setEstado(val),
    defaultValue: estado,
  };

  return (
    <Card className="filtro-card">
      <ScrollAnimation offset={100} duration={1} animateIn={'animate__fadeInDown'} animateOnce={true}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Filtrar por:
          </Typography>
          <Row>
            <Col md={5} xl={4}>
              <Typography gutterBottom variant="subtitle1" component="div">
                Busqueda por id
              </Typography>
              <TextField type="number" value={titulo} onChange={(e) => setTitulo(e.target.value)} variant="outlined" />
            </Col>
            {/* <Col md={5} xl={4}>
              <Typography gutterBottom variant="subtitle1" component="div">
                Creado desde
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date desktop"
                  inputFormat="dd/MM/yyyy"
                  value={fecha}
                  onChange={setFecha}
                  renderInput={(params) => <TF {...params} />}
                />
              </LocalizationProvider>
            </Col> */}
          </Row>
          <Row style={{ gap: '20px' }}>
            {/* <Col sm={12} md={5} xl={4}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Operacion</InputLabel>
                <Select multiple {...customProps}>
                  {(Object.keys(propiedadOperation) as Array<keyof typeof propiedadOperation>).map((x) => (
                    <MenuItem value={propiedadOperation[x]}>{propiedadOperation[x]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col sm={12} md={5} xl={4}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Tipo de propiedad</InputLabel>
                <Select multiple {...customProps2}>
                  {(Object.keys(propiedadType) as Array<keyof typeof propiedadType>).map((x) => (
                    <MenuItem value={propiedadType[x]}>{propiedadType[x]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col sm={12} md={5} xl={3}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Estado de publicacion</InputLabel>
                <Select multiple {...customProps3}>
                  <MenuItem value={'Destacado'}>Destacado</MenuItem>
                  <MenuItem value={'Pagina Principal'}>Pagina Principal</MenuItem>
                </Select>
              </FormControl>
            </Col>
            <Col md={5} xl={4}>
              <Typography gutterBottom variant="subtitle1" component="div">
                Tipo de moneda
              </Typography>
              <UnstyledSelectsMultiple handleChange={(val: any) => setPrecio(val)} defaultValue={precio} options={['USD']} />
            </Col> */}
          </Row>
        </CardContent>
      </ScrollAnimation>
    </Card>
  );
};

export default PropiedadFilter;
