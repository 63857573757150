import { Alert } from '@mui/material';
import React, { useContext } from 'react';
import { NotificationsContext } from '../../shared/NotificationsProvider';
import './Notification.scss';

export default function Notifications() {
  const { notifications, dismiss } = useContext(NotificationsContext);

  return (
    <div className="notifications">
      <ul className="list">
        {notifications.map((notification) => (
          <li key={notification.id}>
            <Alert severity={notification.type} onClose={() => dismiss(notification.id)}>
              {notification.message}
            </Alert>
          </li>
        ))}
      </ul>
      <ul id="notifications-portal" className="list"></ul>
    </div>
  );
}
