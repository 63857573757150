import { useState } from 'react';
import './CartaPropiedad.scss';
import { Card, Badge, Row, Col } from 'react-bootstrap';
import { IPropiedad, propiedadOperation } from '../../shared/types';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FormPropiedades from '../../components/FormPropiedades/FormPropiedades';
import defaultImage from '../../assets/logo.png';
import { apiCall } from '../../shared/service';
import useNotify from '../../shared/useNotify';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import ConfirmationDialogRaw from '../ConfirmationDialog/ConfirmationDialog';
import AmbIcon from '../../assets/icons/mts2.png';
import MtsIcon from '../../assets/icons/plano.png';
import LocIcon from '../../assets/icons/location.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

type CartaProps = {
  refetch: () => void;
  admin?: boolean;
} & IPropiedad;

const CartaPropiedad = (props: CartaProps) => {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const [confirmationId, setConfirmationId] = useState<number | null>(null);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleConfirmation = (confirmed?: boolean) => {
    if (confirmed) {
      apiCall(`/propiedad/${props.id}`, 'DELETE', {
        deleted: true,
      }).then(() => {
        notify.info(`${props.titulo ?? 'Propiedad'} eliminado`);
        props.refetch();
      });
    }

    setConfirmationId(null);
  };

  const openPropiedad = () => {
    window.open(`/propiedades/${props.id}${props.activo ? '' : '?preview=true'}`, '_blank');
  };

  return (
    <div className="container-depto" style={{ width: '100%', boxShadow: '0 8px 8px 0 #0001', borderRadius: '8px' }}>
      <ScrollAnimation offset={0} duration={1} animateIn={'animate__fadeIn'} animateOnce={true}>
        <Card className="card-container">
          <Card.Body className="card-body">
            <Row>
              <Col sm={12} md={3} lg={3}>
                {props.destacado && props.admin && <Badge className="destacado">Destacado</Badge>}
                {props.paginaPrincipal && props.admin && <Badge className="pag-principal">Pagina Principal</Badge>}
                {props.admin && (
                  <div className="actions">
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        setConfirmationId(props.id ?? null);
                      }}
                    >
                      Borrar
                    </Button>
                    <Button
                      variant="contained"
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleOpen();
                      }}
                    >
                      Editar
                    </Button>
                  </div>
                )}
                <Swiper pagination={true} className="mySwiper" modules={[Pagination]}>
                  {(props.imagenes?.length ? props.imagenes : [defaultImage])?.map((imagenes, index) => (
                    <SwiperSlide key={`${index}img`}>
                      <img
                        alt="img"
                        className="imagen"
                        width={'100%'}
                        style={{ borderRadius: 8, objectFit: props?.imagenes?.length ? 'cover' : 'contain' }}
                        src={imagenes?.imageBlob ? imagenes.imageBlob : imagenes}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Col>
              <Col
                sm={12}
                md={9}
                lg={9}
                onClick={openPropiedad}
                onMouseDown={(e) => {
                  if (e.button === 1) {
                    openPropiedad();
                  }
                }}
                className="info"
              >
                <Row className="main-info">
                  <Col className="direccion" sm={8} md={10} lg={8}>
                    {props.calle ? props.calle : props.direccion}
                  </Col>
                  <Col sm={2} md={2} className="price">
                    {props.valor ? (
                      props.enDolares ? (
                        <p>$usd {props.valor.toLocaleString()}</p>
                      ) : (
                        <p>$ {props.valor.toLocaleString()}</p>
                      )
                    ) : (
                      <p>Consultar</p>
                    )}
                  </Col>
                </Row>
                <Row style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                  <div className="type-barrio">
                    <div className="type-prop">
                      <p style={{ display: 'flex', gap: '5px' }}>
                        {props.tipo}
                        &nbsp; en &nbsp;
                        {props.operacion === propiedadOperation.AlquilerTemporal ? 'Temporal' : props.operacion}
                      </p>
                    </div>
                    <div className="text-gray" style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      <img src={LocIcon} alt='ico'/>
                      {props.barrio}
                    </div>
                  </div>
                </Row>
                <Row className="details">
                  <div className="type-barrio text-gray">
                    <div style={{ display: 'flex', alignItems: 'center', gap: 6, borderRight: '1px solid #999', paddingRight: '10px' }}>
                      {props.ambientes ? (
                        <>
                          <img src={MtsIcon} alt='ico'/>
                          {props.ambientes} ambientes
                        </>
                      ) : null}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                      <img src={AmbIcon} alt='ico'/>
                      <p className="text-gray" style={{ margin: 0 }}>
                        {props.superficie ?? 0}
                        <sup>m2</sup>
                      </p>
                    </div>
                  </div>
                </Row>
                <Row className="description">
                  <Col className="d-inline-block" style={{ maxWidth: '100%', maxHeight: '50px' }}>
                    <p className="description-card text-gray">{props.descripcion}</p>
                  </Col>
                </Row>
                <Row className="codigo text-gray">
                  <Col>COD ID {props.id}</Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </ScrollAnimation>
      <ConfirmationDialogRaw
        open={!!confirmationId}
        onClose={(confirmed) => handleConfirmation(confirmed)}
        title="¿Seguro quieres eliminar esta propiedad?"
      />
      <Modal open={open} onClose={() => toggleOpen()} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <div>
          <FormPropiedades
            defaultValues={props}
            onClose={(refetch) => {
              if (refetch) {
                props.refetch();
              }
              toggleOpen();
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CartaPropiedad;
