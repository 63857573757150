import { Formik, Field, Form } from 'formik';
import './Header.scss';
import Select from 'material-ui-formik-components/Select/Select';
import Autocomplete from 'material-ui-formik-components/Autocomplete/Autocomplete';
import { TextField } from 'material-ui-formik-components/TextField';
import search from '../../assets/search-line.svg';
import { useNavigate } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';

const SearchForm = ({ barrios = [] }: { barrios: { barrio: string }[] }) => {
  const history = useNavigate();

  const handleSubmit = (values) => {
    const { Codigo, ...restValues } = values;

    if (Codigo) {
      window.location.replace(`/propiedades/${Codigo}`);
    } else {
      const query = Object.keys(restValues)
        .map((key) => {
          if (restValues[key] !== null && restValues[key] !== undefined && restValues[key] !== '') {
            return typeof restValues[key] !== 'string' ? `${key}=${restValues[key].value}` : `${key}=${restValues[key]}`;
          }
          return null;
        })
        .filter((x) => !!x)
        .join('&');

      history(`/propiedades?${query}`);
    }
  };

  return (
    <div className="searchbox">
      <Formik
        initialValues={{
          tipo: '',
          barrio: null,
          operacion: '',
          Codigo: '',
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <NavDropdown className="dropdown" title="Filtrar" id="basic-nav-dropdown">
            <Field
              name="tipo"
              component={Select}
              options={[
                { value: 'Local', label: 'Local' },
                { value: 'Oficina', label: 'Oficina' },
                { value: 'Galpon', label: 'Galpon' },
                { value: 'Terreno', label: 'Terreno' },
                { value: 'Vivienda', label: 'Vivienda' },
              ]}
              label="Tipo"
              default={{
                value: 'Galpon',
                label: 'Galpon',
              }}
            />
            <Field
              name="operacion"
              component={Select}
              default={{
                value: 'Venta',
                label: 'Venta',
              }}
              options={[
                {
                  value: 'Venta',
                  label: 'Venta',
                },
                {
                  value: 'Alquiler',
                  label: 'Alquiler',
                },
                {
                  value: 'Alquiler temporal',
                  label: 'Alquiler Temporal',
                },
                {
                  value: 'Fondo de comercio',
                  label: 'Fondo de Comercio',
                },
              ]}
              label="Operación"
              placeholder="Operación"
            />
            <Field
              name="barrio"
              component={Autocomplete}
              options={barrios
                ?.filter((barrioObj) => barrioObj?.barrio !== null)
                .map((barrioObj) => ({
                  value: barrioObj?.barrio,
                  label: barrioObj?.barrio,
                }))}
              textFieldProps={{
                label: 'Barrio',
              }}
              placeholder="Barrio"
            />
          </NavDropdown>
          <div className="campos">
            <Field
              name="tipo"
              component={Select}
              options={[
                { value: 'Local', label: 'Local' },
                { value: 'Oficina', label: 'Oficina' },
                { value: 'Galpon', label: 'Galpon' },
                { value: 'Terreno', label: 'Terreno' },
                { value: 'Vivienda', label: 'Vivienda' },
              ]}
              label="Tipo"
              default={{
                value: 'Galpon',
                label: 'Galpon',
              }}
            />
            <Field
              name="operacion"
              component={Select}
              default={{
                value: 'Venta',
                label: 'Venta',
              }}
              options={[
                {
                  value: 'Venta',
                  label: 'Venta',
                },
                {
                  value: 'Alquiler',
                  label: 'Alquiler',
                },
                {
                  value: 'Alquiler temporal',
                  label: 'Alquiler Temporal',
                },
                {
                  value: 'Fondo de comercio',
                  label: 'Fondo de Comercio',
                },
              ]}
              label="Operación"
              placeholder="Operación"
            />
            <Field
              name="barrio"
              component={Autocomplete}
              options={barrios
                ?.filter((barrioObj) => barrioObj?.barrio !== null)
                .map((barrioObj) => ({
                  value: barrioObj?.barrio,
                  label: barrioObj?.barrio,
                }))}
              textFieldProps={{
                label: 'Barrio',
              }}
              placeholder="Barrio"
            />
          </div>
          <Field component={TextField} label="Buscar por código" name="Codigo" /* placeholder="Buscar por codigo" */ />
          <button className="button" type="submit" title="Buscar">
            <img src={search} alt="search" width={25} height={25} />
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default SearchForm;
