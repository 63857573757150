import { useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'material-ui-formik-components';
import { apiCall } from '../../shared/service';
import useNotify from '../../shared/useNotify';
import './Contacto.scss';
import Phone from '../../assets/icons/phone.png';
import Email from '../../assets/icons/email.png';
import comment from '../../assets/icons/comment.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faBuilding, faCommentDots, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import PaperPlane from '../../assets/icons/plane-top.svg';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useState } from 'react';
import { Helmet } from 'react-helmet';

const Contacto = () => {
  const [success, setSuccess] = useState(false);
  const notify = useNotify();
  /* const fields: Array<any> = [
    {
      name: 'nombre',
      label: 'Nombre',
      type: 'text',
      default: '',
      required: true,
      component: TextField,
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      default: '',
      required: true,
      component: TextField,
    },
    {
      name: 'telefono',
      label: 'Telefono',
      type: 'text',
      default: '',
      required: true,
      component: TextField,
    },
    {
      name: 'ofrecer',
      label: 'Tipo de consulta',
      default: 'ofrecer',
      required: true,
      component: Select,
      options: [
        { value: 'ofrecer', label: 'Ofrecer una propiedad' },
        { value: 'solicitar', label: 'Solicitar información' },
      ],
    },
    {
      name: 'mensaje',
      label: 'Mensaje',
      type: 'text',
      default: '',
      required: true,
      multiline: true,
      minRows: 4,
      maxRows: 4,
      component: TextField,
    },
  ]; */

  const initialValues = {
    ofrecer: 'ofrecer',
    nombre: '',
    email: '',
    mensaje: '',
    telefono: '',
  };

  const { id } = useParams();

  const CardContact = ({ icon, text, fAwesome }) => {
    return (
      <div className="card-contact">
        {icon ? <img style={{ width: 32, height: 28 }} src={icon} /> : fAwesome}
        <p style={{ fontWeight: 500 }}>{text}</p>
      </div>
    );
  };

  const SuccessMsj = () => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };

  return (
    <>
      <Helmet>
        <title>Contacto Inmobiliaria Seky Propiedades.</title>
        <meta
          name="description"
          content="Contactar a la Inmobiliaria Seky Propiedades para solicitar u ofrecer en alquiler o venta un galpón en CABA, deposito, local, oficina o vivienda."
        />
      </Helmet>
      <header className="port-contact">
        <div className="bg-contact"></div>
        <h1 style={{ textAlign: 'center' }}>SEKY PROPIEDADES</h1>
      </header>
      <section className="container-cards">
        <CardContact
          icon={null}
          fAwesome={<FontAwesomeIcon size="2x" color="#48e" icon={faLocationDot as IconProp} />}
          text={'Acoyte 110, Local 88 , CABA'}
        />
        <CardContact icon={Phone} fAwesome={null} text={'(+54) 11 4902 8965'} />
        <CardContact icon={Email} fAwesome={null} text={'info@seky.com.ar'} />
      </section>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'center', marginTop: 30 }}>
        <img src={comment} width={24} alt="" />
        <p className="title-contact">Contacto</p>
      </div>
      <div className="contact-form">
        <Formik
          initialValues={initialValues}
          onSubmit={(val, { setSubmitting, resetForm }) => {
            apiCall(`/contacto/mail`, 'POST', { ...val, id }).then(() => {
              /* notify.info('Su mensaje se ha enviado '); */
              SuccessMsj();
              resetForm(initialValues as any);
            });
            setSubmitting(false);
          }}
        >
          <Form className="fields-container2">
            {success && (
              <div style={{ backgroundColor: '#48e6', width: '300px', padding: '12px', borderRadius: 5, textAlign: 'center', margin: '0 auto' }}>
                Su mensaje ha sido enviado
              </div>
            )}
            <div className="container-inputs">
              <FontAwesomeIcon color="#8c8c8c" className="icon-contact2" icon={faUser as IconProp} />
              <Field className="fields" name="nombre" label="Nombre" type="text" default="" required={true} component={TextField} />
            </div>
            <div className="container-inputs">
              <FontAwesomeIcon color="#8c8c8c" className="icon-contact2" icon={faEnvelope as IconProp} />
              <Field className="fields" name="email" label="Email" type="email" default="" required={true} component={TextField} />
            </div>
            <div className="container-inputs">
              <FontAwesomeIcon color="#8c8c8c" className="icon-contact2" icon={faPhone as IconProp} />
              <Field className="fields" name="telefono" label="Telefono" type="text" default="" required={true} component={TextField} />
            </div>
            <div>
              <label htmlFor="select">Tipo de consulta</label>
              <div className="container-inputs" style={{ height: '50px' }}>
                <FontAwesomeIcon color="#8c8c8c" className="icon-contact2" icon={faBuilding as IconProp} />
                <Field
                  id="select"
                  className="fields"
                  name="ofrecer"
                  type="text"
                  default="ofrecer"
                  required={true}
                  component={Select}
                  options={[
                    { value: 'ofrecer', label: 'Ofrecer una propiedad' },
                    { value: 'solicitar', label: 'Solicitar información' },
                  ]}
                />
              </div>
            </div>
            <div className="container-inputs area">
              <FontAwesomeIcon color="#8c8c8c" className="icon-message" icon={faCommentDots as IconProp} />
              <Field
                className="fields"
                name="mensaje"
                label="Mensaje"
                type="text"
                default=""
                required={true}
                multiline={true}
                minRows={4}
                maxRows={4}
                component={TextField}
              />
            </div>
            <div className="button-container">
              <button type="submit" className="button-contact">
                Enviar <img className="icon-plane" src={PaperPlane} alt="submit" />
              </button>
            </div>
          </Form>
        </Formik>
        <iframe
          title="Seky map"
          src="https://maps.google.com/maps?f=q&source=s_q&hl=en&q=Avenida+Acoyte+110+Ciudad+Autónoma+de+Buenos+Aires+Argentina&aq=&sll=-34.6170134,-58.4371807&output=embed"
          width="100%"
          height="500"
          frameBorder={'0'}
          scrolling="no"
          style={{ border: 0, borderRadius: 20 }}
          allowFullScreen={true}
          loading="lazy"
        ></iframe>
      </div>
    </>
  );
};

export default Contacto;
