import { DataGrid, GridCellParams, GridColDef, GridRenderCellParams, esES } from '@mui/x-data-grid';
import { useState } from 'react';
import useNotify from '../../shared/useNotify';
import { apiCall } from '../../shared/service';
import ConfirmationDialogRaw from '../../components/ConfirmationDialog/ConfirmationDialog';
import FormPropiedades from '../../components/FormPropiedades/FormPropiedades';
import { Button, Modal } from '@mui/material';
import noImage from '../../assets/no-image.jpg';

function Table({ propiedades, loading, refetch }) {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const [confirmationId, setConfirmationId] = useState<number | null>(null);
  const [propId, setpropId] = useState();
  const [propiedad, setPropiedad] = useState();

  const toggleOpen = async () => {
    if (propId) {
      try {
        const res = await apiCall(`/propiedad/${propId}`, 'GET');
        setPropiedad(res);
        setTimeout(() => {
          setOpen(!open);
        }, 500);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleConfirmation = (confirmed?: boolean) => {
    if (confirmed) {
      apiCall(`/propiedad/${confirmationId}`, 'DELETE', {
        deleted: true,
      }).then(() => {
        notify.info(`${confirmationId ?? 'Propiedad'} eliminado`);
        refetch();
      });
    }

    setConfirmationId(null);
  };

  const Buttons = ({ params }: { params: GridCellParams }) => {
    return (
      <div className="actions">
        <Button
          variant="contained"
          color="warning"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setConfirmationId(params.row.id ?? null);
          }}
        >
          Borrar
        </Button>
        <Button
          variant="contained"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            setpropId(params.row.id);
            toggleOpen();
          }}
        >
          Editar
        </Button>
      </div>
    );
  };

  const RenderImage = (params: GridRenderCellParams<any, any>) => {
    return (
      params.value &&
      (params.value.length >= 1 ? (
        <img style={{ objectFit: 'contain', width: '100%', height: '80px' }} src={params.value[0].imageBlob} alt="portada" />
      ) : (
        <img style={{ objectFit: 'contain', width: '100%', height: '55px' }} src={noImage} alt="imagen" />
      ))
    );
  };

  const RenderValor = (params: GridRenderCellParams<any, any>) => {
    return (
      <p style={{ margin: 0 }}>
        {params.value && params.row.enDolares ? 'usd' : ''}
        {params.value ? '$' + params.value : 'Sin precio'}
      </p>
    );
  };

  const RenderFecha = (params: GridRenderCellParams<any, any>) => {
    const Fecha = new Date(params.value);

    const año = Fecha.getFullYear();
    const mes = Fecha.getMonth();
    const dia = Fecha.getDay();
    return (
      <p style={{ margin: 0 }}>
        {dia} / {mes} / {año}
      </p>
    );
  };

  const RenderSubtipo = (params: GridRenderCellParams<any, any>) => {
    const Subtipo = () => {
      return (
        <p style={{ margin: 0 }}>
          {params.row.subtipo_local === 'fondo' && 'Fondo de comercio'}
          {params.row.subtipo_local === 'local' && 'Local'}
        </p>
      );
    };
    return <p style={{ margin: 0 }}>{params.value ? params.value : params.row.subtipo_local ? <Subtipo /> : '-'}</p>;
  };

  const RenderDesc = (params: GridRenderCellParams<any, any>) => {
    return <p style={{ margin: 0 }}>{params.value ? params.value : '-'}</p>;
  };

  const RenderId = (params: GridRenderCellParams<any, any>) => {
    return (
      <p style={{ margin: 0 }}>
        ID: <b>{params.row.id}</b>
      </p>
    );
  };

  const RenderDest = (params: GridRenderCellParams<any, any>) => {
    return <p style={{ margin: '0', color: params.value ? '#48e' : '#f13' }}>{params.value ? 'Destacado' : 'Sin destacar'}</p>;
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      renderCell: RenderId,
    },
    {
      field: 'imagenes',
      headerName: 'Portada',
      width: 160,
      renderCell: RenderImage,
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      width: 150,
    },
    {
      field: 'subtipo',
      headerName: 'SubTipo',
      width: 150,
      renderCell: RenderSubtipo,
    },
    {
      field: 'operacion',
      headerName: 'Operacion',
      width: 160,
    },
    {
      field: 'direccion',
      headerName: 'Direccion',
      width: 200,
    },
    {
      field: 'valor',
      headerName: 'valor',
      width: 120,
      renderCell: RenderValor,
    },
    {
      field: 'descripcion',
      headerName: 'Descripcion',
      width: 200,
      editable: true,
      renderCell: RenderDesc,
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de creacion',
      width: 160,
      renderCell: RenderFecha,
    },
    {
      field: 'destacado',
      headerName: 'Destacado',
      width: 160,
      renderCell: RenderDest,
    },
    { field: 'expensas', headerName: 'Acciones', width: 200, renderCell: (params) => <Buttons params={params} /> },
  ];

  return (
    <div>
      <DataGrid
        pageSizeOptions={[10]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        rows={propiedades}
        loading={loading}
        columns={columns}
        rowSelection={false}
      />
      <ConfirmationDialogRaw
        open={!!confirmationId}
        onClose={(confirmed) => handleConfirmation(confirmed)}
        title="¿Seguro quieres eliminar esta propiedad?"
      />
      <Modal open={open} onClose={() => toggleOpen()} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <div>
          <FormPropiedades
            defaultValues={propiedad && propiedad}
            onClose={(e) => {
              if (e) {
                refetch();
              }
              setOpen(!open);
            }}
          />
        </div>
      </Modal>
    </div>
  );
}

export default Table;
