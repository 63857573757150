import { useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import './DetallePropiedad.scss';
import { TextField } from 'material-ui-formik-components';
import { apiCall } from '../../shared/service';
import useNotify from '../../shared/useNotify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import PaperPlane from '../../assets/icons/plane-top.svg';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
const ContactForm = ({ code, prop }) => {
  const notify = useNotify();
  const fields: Array<any> = [
    {
      className: 'fields',
      name: 'email',
      label: 'Email',
      type: 'email',
      default: '',
      required: true,
      component: TextField,
    },
    {
      className: 'fields',
      name: 'telefono',
      label: 'Telefono',
      type: 'text',
      default: '',
      required: true,
      component: TextField,
    },
    {
      className: 'fields',
      name: 'mensaje',
      label: 'Mensaje',
      type: 'text',
      default: '',
      required: true,
      multiline: true,
      minRows: 6,
      maxRows: 6,
      component: TextField,
    },
  ];
  const initialValues = {
    email: '',
    mensaje: '',
    telefono: '',
  };

  const { id } = useParams();

  return (
    <div className="contact-form2">
      <p className="form-contact">Contactar por esta propiedad</p>
      <p className="info-form">
        {prop.tipo} en {prop.operacion} CODE ID {code}
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={(val, { setSubmitting, resetForm }) => {
          apiCall(`/contacto/mail`, 'POST', { ...val, id }).then(() => {
            notify.info('Su mensaje se ha enviado ');
            resetForm(initialValues as any);
          });
          setSubmitting(false);
        }}
      >
        <Form className="fields-container">
          {Object.keys(fields).map((x) => {
            const defaultProp: any = {};

            return (
              <div className="input-type">
                <Field {...defaultProp} className="fields" key={x} {...fields[x as keyof typeof fields]} />
              </div>
            );
          })}
          <div className="button-container">
            <button type="submit" className="button-form">
              Enviar Mensaje
              <img className="icon-plane2" src={PaperPlane} alt="ico" />
            </button>
            <a href="https://api.whatsapp.com/send?phone=541153334258&" target="_blank" rel="noopener noreferrer" className="button-form2 wsp">
              <FontAwesomeIcon className="icon-phone" icon={faPhone as IconProp} />
              Contactar por telèfono
            </a>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ContactForm;
