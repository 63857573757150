import './App.scss';
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Admin from './pages/admin/Admin';
import Main from './pages/main/Main';
import NavbarComponent from './components/Navbar/Navbar';
import LoginPage from './pages/admin/LoginPage';
import { verifySession } from './shared/service';
// import Contactos from './pages/admin/Contacto';
import NotificationsProvider from './shared/NotificationsProvider';
import Notifications from './components/Notifications/Notifications';
import DetallePropiedad from './components/DetallePropiedad/DetallePropiedad';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Contacto from './pages/main/Contacto';
import Footer from './pages/main/Footer';
import SearchPage from './pages/search/SearchPage';
import NotAccess from './components/404';

function App() {
  const [user, setUser] = React.useState<{ name: string; auth: boolean } | null>(null);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');

    if (token) {
      verifySession(token)
        .then(() => setUser({ name: 'admin', auth: true }))
        .catch(() => setUser({ name: '', auth: false }));
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <NotificationsProvider>
          <NavbarComponent loggedIn={user?.auth} setUser={setUser} />
          <div>
            <Routes>
              {user?.auth ? (
                <>
                  <Route path="/admin" element={<Admin />} />
                </>
              ) : (
                <Route path="/admin" element={<LoginPage setUser={setUser} />} />
              )}
              <Route path="*" element={<Main />} />
              <Route path="/contacto" element={<Contacto />} />
              {/* <Route path="/propiedad/:id" element={<NotAccess />} /> */}
              <Route path="/propiedades/:id" element={<DetallePropiedad />} />
              <Route path="/propiedades" element={<SearchPage />} />
            </Routes>
          </div>
          <Notifications />
        </NotificationsProvider>
        <a
          target="_blank"
          style={{ zIndex: 9999 }}
          className="floating-wpp-btn"
          href={`https://api.whatsapp.com/send?phone=541153334258&text=Hola`}
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faWhatsapp as IconDefinition} />
        </a>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
