import Header from './Header';
import Body from './Body';
import './Main.scss';
import { Helmet } from 'react-helmet';
import About from './SobreNosotros';
import Services from './Servicios';

const Main = () => {
  return (
    <>
      <Helmet
        title={'Seky Propiedades'}
        htmlAttributes={{ lang: 'es' }}
        meta={[
          {
            name: `descripcion`,
            content:
              'Seky Propiedades, es una inmobiliaria comercial ubicada en el centro geográfico de la Ciudad de Buenos Aires que ofrece: Inmuebles Industriales: Alquiler y Venta de Depósitos, Galpones y Edificios Industriales ubicados principalmente en la Zona Centro y Sur de la Capital Federal. Inmuebles Industriales: Alquiler y Venta de Depósitos, Galpones y Edificios Industriales ubicados principalmente en la Zona Centro y Sur de la Capital Federal. Inmuebles Residenciales: Alquiler y venta de departamentos en la Ciudad de Buenos Aires. Servicio de Tasaciones judiciales y privadas con el aval de martilleros especializados.',
          },
          {
            name: 'keywords',
            content:
              'Inmobiliaria, Alquiler, Venta, Galpon, Galpones, Industrial, Departamentos Temporales, Departamentos, Inmobiliaria comercial, Buenos Aires, CABA, Capital, Inmuebles Industriales, Depositos, Edificios industriales, Capital federal, Servicio de Tasaciones judiciales, martilleros especializados',
          },
        ]}
      />
      <Header />
      <Body />
      <About />
      <Services />
    </>
  );
};

export default Main;
