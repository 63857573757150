import './Servicios.scss';

function Services() {
  return (
    <>
      <section className="container-services">
        <div className="container-lista">
          <h4 className="title-services">Búsquedas populares o recientes</h4>
          <ul className="lista">
            <li>Galpones y Depósitos Capital Federal</li>
            <li>Galpones en Barracas</li>
            <li>Galpones en Pompeya</li>
            <li>Alquiler de locales en caballito</li>
            <li>Ventas de Fondos de Comercio</li>
            <li>Alquiler de departamentos en Puerto Madero</li>
            <li>Galpones en Buenos Aires</li>
          </ul>
        </div>
        <div className="container-lista">
          <h4 className="title-services">Servicios</h4>
          <ul className="lista">
            <li>Confeccion de contratos de locacion</li>
            <li>Evaluación de solvencia de inquilinos y garantes</li>
            <li>Administracion Cobranza de alquileres</li>
            <li>Asesoramiento júridico sobre contratos de locacion</li>
            <li>Situacion júridica de inmuebles en venta</li>
            <li>Servicio de Tasaciones</li>
            <li>Asesoramiento en general</li>
          </ul>
        </div>
      </section>
    </>
  );
}

export default Services;
