import './Header.scss';
import SearchForm from './SearchForm';
import { useEffect, useState } from 'react';
import { apiCall } from '../../shared/service';

const Header = () => {
  const [barrios, setBarrios] = useState([]);

  useEffect(() => {
    apiCall(`/propiedad/barrios`, 'GET').then((res: any) => {
      setBarrios(res);
    });
  }, []);

  return (
    <div className="main-header">
      <h2 className="title-header">INMUEBLES EN ALQUILER Y VENTA</h2>
      <SearchForm barrios={barrios && barrios} />
    </div>
  );
};

export default Header;
