import './CardGrid.scss';
import { ButtonGroup, Dropdown, DropdownButton, Pagination } from 'react-bootstrap';
import { useCallback } from 'react';

type IPaginationProps = {
  propiedadesPerPage: number;
  totalPropiedades: number;
  currentPage: number;
  setCurrentPage: (val: number) => void;
  setPropiedadesPerPage: (val: number) => void;
};

const PaginationComponent = ({ propiedadesPerPage, totalPropiedades, currentPage, setCurrentPage, setPropiedadesPerPage }: IPaginationProps) => {
  const handlePageChange = (val: number) => {
    const total = totalPropiedades / propiedadesPerPage;

    if (val <= total && val >= 1) {
      setCurrentPage(val);
      window.scrollTo(0, 0);
    }
  };

  const { floor, min, max } = Math;
  const range = (lo: number, hi: number) => Array.from({ length: hi - lo }, (_, i) => i + lo);

  const pagination = useCallback(
    (count: number, page: number, total: number) => {
      const start = max(1, min(page - floor((count - 3) / 2), total - count + 2));

      const end = min(total, max(page + floor((count - 2) / 2), count - 1));

      return [
        ...(start > 2 ? [1, '...'] : start > 1 ? [1] : []),
        ...range(start, end + 1),
        ...(end < total - 1 ? ['...', total] : end < total ? [total] : []),
      ];
    },
    [currentPage, totalPropiedades, propiedadesPerPage],
  );

  return (
    <div className="pagination-container">
      <Pagination className="pagination">
        {/* <Pagination.First onClick={() => handlePageChange(1)} /> */}
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
        {pagination(5, currentPage, Math.floor(totalPropiedades / propiedadesPerPage)).map((item, index) => {
          if (typeof item === 'number') {
            return (
              <Pagination.Item style={{ border: 'none !important' }} onClick={() => handlePageChange(item)} key={index} active={item === currentPage}>
                {item}
              </Pagination.Item>
            );
          }
          return <Pagination.Ellipsis key={`ellipsis-${index}`} />;
        })}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
        {/* <Pagination.Last onClick={() => handlePageChange(Math.floor(totalPropiedades / propiedadesPerPage))} /> */}
      </Pagination>
      {/*  <DropdownButton
        onSelect={(evt) => setPropiedadesPerPage(evt ? parseInt(evt) : 1)}
        className="pagination-per-item"
        as={ButtonGroup}
        title={`${propiedadesPerPage} Items`}
        id="bg-nested-dropdown"
      >
        {[10, 20, 50, 100].map((item, index) => {
          return (
            <Dropdown.Item key={index} eventKey={item}>
              {item}
            </Dropdown.Item>
          );
        })}
      </DropdownButton> */}
    </div>
  );
};

export default PaginationComponent;
