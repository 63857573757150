/* eslint-disable max-len */
import './DetallePropiedad.scss';
import ContactForm from './ContactForm';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Suspense, useEffect, useState } from 'react';
import { apiCall } from '../../shared/service';
import { IPropiedad, propiedadOperation } from '../../shared/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes, faCaretRight, faCamera, faAngleRight, faAngleLeft, faToilet } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import ModalGallery from './ModalGallery';
import Modal from '@mui/material/Modal';
import { Col, Row } from 'react-bootstrap';
import { Card, IconButton } from '@mui/material';
import Logo from '../../assets/logo.png';
import { startCase } from 'lodash';
import { detalleArray, detalleMap } from './utils';
import useNotify from '../../shared/useNotify';
import DetallePropiedadLoading from './DetallePropiedadLoading';
import { Helmet } from 'react-helmet';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
/* import google from 'googlemaps'; */
import IconUbi from '../../assets/icons/location.png';
import IconAmb from '../../assets/icons/casa.png';
import IconMts from '../../assets/icons/mts2.png';
import LoadMap from '../map';
import Icon from '../../assets/icons/location.png';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
export const useQuery = () => new URLSearchParams(useLocation().search);

const DetallePropiedad = () => {
  const params = useParams();
  const [propiedad, setPropiedad] = useState<IPropiedad | null>(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(Boolean);
  const [currentImage, setCurrentImage] = useState(0);
  const images = propiedad?.imagenes || [];

  const prevSlide = () => {
    if (currentImage === 0) {
      setCurrentImage(images.length - 1);
    } else {
      setCurrentImage(currentImage - 1);
    }
  };

  const nextSlide = () => {
    if (currentImage === images.length - 1) {
      setCurrentImage(0);
    } else {
      setCurrentImage(currentImage + 1);
    }
  };

  const notify = useNotify();
  const query = useQuery();
  const history = useNavigate();

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleClick = () => {
    navigator.clipboard.writeText(window.location.href).then(() => notify.info('Copiado al portapapeles'));
  };

  useEffect(() => {
    if (currentImage === 0) {
      setActive(true);
    } else if (currentImage === images.length - 1) {
      setActive(false);
    }
  }, [currentImage]);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    apiCall(`/propiedad/${params.id}${Boolean(query.get('preview')) ? '/preview' : ''}`, 'GET')
      .then((res) => {
        setPropiedad(res);
        setLoading(false);
      })
      .catch(() => history('/'));
  }, []);

  if (!propiedad || loading) {
    return <DetallePropiedadLoading />;
  }

  const PrecioComponent = () => {
    return (
      <>
        <Col md={12}>
          <div className="inmueble">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                flexWrap: 'wrap',
              }}
            >
              <p className="direccion">{propiedad.direccion}</p>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <img src={IconUbi} />
                <p className="barrio">{propiedad.barrio}</p>
                <a className="map-arrow" href="#map">
                  Ver Mapa
                </a>
              </div>
            </div>
            <div onClick={handleClick} className="share-button" style={{ backgroundColor: '#ededed', borderRadius: 10 }}>
              Compartir
              <IconButton>
                <FontAwesomeIcon color="#48e" icon={faShareNodes as IconProp} />
              </IconButton>
            </div>
          </div>
          <div className="price-vivienda">
            <p className="precio">
              {propiedad.enDolares ? 'u$s ' : '$ '}
              {propiedad.operacion === propiedadOperation.Venta
                ? propiedad.valor
                  ? propiedad.valor?.toLocaleString('es-AR')
                  : 'Consultar'
                : propiedad.valorMes
                ? propiedad.valorMes?.toLocaleString('es-AR')
                : 'Consultar'}
            </p>
            <p className="type-prop">
              {propiedad.tipo} en {propiedad.operacion}
            </p>
          </div>
        </Col>
      </>
    );
  };

  const Galeria = () => {
    return (
      <Row className="galeria">
        <Col sm={12} md={8}>
          {propiedad?.imagenes?.length > 0 ? (
            <>
              <Zoom>
                <img className="image-index" style={{ borderRadius: '10px !important' }} src={images[currentImage].imageBlob} />
              </Zoom>
              <span className="pictures">
                <FontAwesomeIcon icon={faCamera as IconProp} />
                {propiedad?.imagenes?.length} Fotos
              </span>
              {propiedad?.imagenes?.length >= 2 && (
                <div className="controls">
                  <div onClick={prevSlide} className="control-left controls-arrow">
                    <FontAwesomeIcon icon={faAngleLeft as IconProp} />
                  </div>
                  <div onClick={nextSlide} className="control-right controls-arrow">
                    <FontAwesomeIcon icon={faAngleRight as IconProp} />
                  </div>
                </div>
              )}
            </>
          ) : (
            <div style={{ display: 'grid', justifyItems: 'center' }}>
              <img className="image-index no-photo" src={Logo} />
            </div>
          )}
        </Col>
        <Col sm={12} md={4}>
          <div className="thumbs-image">
            {propiedad?.imagenes.slice(0, 3).map((e, index) => {
              return e.imageBlob ? <img onClick={() => setCurrentImage(index)} className="images-thumbs" src={e.imageBlob} /> : <img src={Logo} />;
            })}
          </div>
        </Col>
      </Row>
    );
  };
  console.log(propiedad);
  return (
    <section className="props-page">
      <Helmet
        htmlAttributes={{ lang: 'es' }}
        meta={[
          {
            name: `operacion`,
            content: propiedad.operacion,
          },
          {
            name: `descripcion`,
            content: `Seky Propiedades inmobiliaria ofrece mas de 800 propiedades de ${propiedad.tipo} en ${propiedad.operacion} en ${propiedad.barrio}, ingrese a nuestro listado de ${propiedad.tipo} en alquiler y venta en CABA y alrededores.`,
          },
          {
            name: `barrio`,
            content: propiedad.barrio,
          },
          {
            name: 'keywords',
            content: [propiedad.ambientes, propiedad.direccion, propiedad.calle, propiedad.operacion, propiedad.barrio, propiedad.operacion]
              .filter((prop) => !!prop)
              .join(','),
          },
        ].filter((meta) => !!meta)}
      >
        <title>
          {propiedad.titulo
            ? propiedad.titulo + ` | ${propiedad.barrio}`
            : propiedad.direccion
            ? propiedad.direccion + ` | ${propiedad.barrio}`
            : ' Seky Propiedades'}
        </title>
      </Helmet>
      <>
        <div className="detalles-wrapper">
          <header className="header-detalles">
            <div className="index-page">
              <a className="pagination-detalles" style={{ textDecoration: 'none' }} href="/">
                Inicio
              </a>{' '}
              /{' '}
              <a className="pagination-detalles" style={{ textDecoration: 'none' }} href="/propiedades">
                Propiedades
              </a>{' '}
              / Detalle de propiedad
            </div>
          </header>
          <Row className="main-detalles">
            <Galeria />
            <Col className="informacion" md={12}>
              <Row className="container-detalles-flex">
                <Col xs={12} sm={6} md={6} lg={7} xl={8}>
                  <div className="">
                    <Row style={{ position: 'relative' }}>
                      <PrecioComponent />
                    </Row>
                  </div>
                  <div className="detalles-inmueble">
                    <Row>
                      <Col className="caracteristicas">
                        {propiedad.ambientes ? (
                          <p>
                            <img src={IconAmb} />
                            {` ${propiedad.ambientes} ambiente${propiedad.ambientes > 1 && 's'}`}
                          </p>
                        ) : null}
                        {propiedad.superficie ? (
                          <p>
                            <img src={IconMts} />
                            {` ${propiedad.superficie} mts`}
                            <sup>2</sup>
                          </p>
                        ) : null}
                        {propiedad.baños > 0 ? (
                          <p>
                            <FontAwesomeIcon color="#48e" size="lg" icon={faToilet as IconProp} />
                            {` ${propiedad.baños} baño${propiedad.baños > 1 ? 's' : ''}`}
                          </p>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      {propiedad && (
                        <div className="detalle-titulo">
                          <FontAwesomeIcon color="#48e" style={{ marginRight: 10 }} icon={faCaretRight as IconProp} />
                          {propiedad.titulo}
                        </div>
                      )}
                      <Col className="descripcion">{propiedad.descripcion} </Col>
                    </Row>
                  </div>
                  <div className="">
                    <Row>
                      <div className="detalle-titulo">
                        <FontAwesomeIcon color="#48e" style={{ marginRight: 10 }} icon={faCaretRight as IconProp} />
                        Caracteristicas
                      </div>
                    </Row>
                    <Row className="lista-detalle">
                      {detalleArray
                        .filter((x) => !!propiedad[x])
                        .map((key) => {
                          const displayKey = key === 'superficie' ? 'superficie total' : key;
                          return (
                            <div key={`detalle-${key}`}>
                              <span style={{ fontWeight: 400, color: '#666' }}>{startCase(displayKey)}:</span>{' '}
                              <span style={{ fontWeight: 500 }}>
                                {typeof propiedad[key] === 'boolean' ? (propiedad[key] ? 'Si' : 'No') : propiedad[key]}{' '}
                              </span>
                              <span style={{ fontWeight: 500 }}>{detalleMap[key]?.suffix ? detalleMap[key].suffix : ''}</span>
                            </div>
                          );
                        })}
                    </Row>
                    {propiedad.otros?.filter((x) => !!x)?.length ? (
                      <>
                        <Row>
                          <div className="detalle-titulo">Otros</div>
                        </Row>
                        <Row>
                          <Col className="otros">
                            {propiedad.otros
                              .flat()
                              ?.filter((x) => !!x)
                              .map((otro, index) => {
                                return (
                                  <div key={`otro-${index}`}>
                                    <p>{otro}</p>
                                  </div>
                                );
                              })}
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </div>
                  <p className="detalle-titulo">
                    <FontAwesomeIcon color="#48e" style={{ marginRight: 10 }} icon={faCaretRight as IconProp} />
                    Ubicaciòn
                  </p>
                  <div style={{ margin: '5px' }}>
                    <img src={Icon} />
                    {propiedad.calle} / {propiedad.barrio}
                  </div>
                </Col>
                <Col className="col-contact" xs={12} sm={6} md={6} lg={5} xl={4}>
                  <Card className="carta-info carta-info-contact">
                    <ContactForm prop={propiedad} code={propiedad && propiedad.id} />
                  </Card>
                </Col>
                <Col className="google-map" id="map" md={12}>
                  {/* {propiedad && (
                    
                  )} */}
                  {propiedad.lngLat ? (
                    <LoadMap
                      lat={parseFloat(propiedad && propiedad.lngLat?.split(',')[1])}
                      lng={parseFloat(propiedad && propiedad.lngLat?.split(',')[0])}
                      title={propiedad && propiedad.calle}
                    />
                  ) : (
                    'Sin informacion de ubicacion'
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {open && (
          <Modal open={open} onClose={() => toggleOpen()} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <ModalGallery imagenes={propiedad.imagenes} onClose={() => toggleOpen()} />
          </Modal>
        )}
      </>
    </section>
  );
};

export default DetallePropiedad;
