// Importing the required libraries
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import './LoginPage.scss';
import Fondo from '../../assets/fondo_login.jpg';
import { useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const config = process.env;

const LoginPage = ({ setUser }: { setUser: ({ auth, name }: { auth: boolean; name: string }) => void }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState('');
  const history = useNavigate();

  const host = typeof window !== 'undefined' && window.location.origin;

  const handleSubmit = () => {
    setErrors('');
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios
      .post(`${host}/login`, { name: username, password })
      .then((res) => {
        localStorage.setItem('jwtToken', res.data.token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        setUser({ auth: true, name: res.data.username });
        history('/admin');
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            setErrors('El usuario y contraseña son incorrectos');
          } else {
            setErrors('Error inesperado');
          }
        }
        console.log(err);
      });
  };

  return (
    <div className="login-container">
      <div style={{ backgroundImage: `url(${Fondo})` }} className="fondo" />
      <div className="dark-bg" />
      <Card className="login-card">
        <CardContent>
          <Typography gutterBottom variant="h5" textAlign="center" component="div">
            Admin - Log In
          </Typography>
          <TextField onChange={(e) => setUsername(e.target.value)} id="user" label="Usuario" variant="standard" />
          <TextField onChange={(e) => setPassword(e.target.value)} id="passsword" label="Contraseña" variant="standard" type="password" />

          {errors && <FormHelperText style={{ color: 'red' }}>{errors}</FormHelperText>}
          <Button onClick={handleSubmit} disabled={!username || !password} sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
            Log In
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default LoginPage;
