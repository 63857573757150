import CartaPropiedad from '../CartaPropiedad/CartaPropiedad';
import { Skeleton } from '@mui/material';
import { Card, Row, Col } from 'react-bootstrap';
import './CardGrid.scss';

const CardGrid = ({ shownProp, currentPage, propiedadesPerPage, fetchPropiedades, admin, loading }) => {
  return (
    <div className="grid-container">
      {!loading ? (
        (shownProp ?? [])
          .slice(propiedadesPerPage * (currentPage - 1), propiedadesPerPage * currentPage)
          .map((propiedad, index) => <CartaPropiedad admin={admin} refetch={() => fetchPropiedades()} key={`${index}card`} {...(propiedad as any)} />)
      ) : (
        <div style={{ width: '100%' }}>
          {[...Array(10)].map((n, idx) => (
            <Card
              style={{
                padding: '20px',
                marginTop: '20px',
              }}
              key={`${idx}-skeleton`}
            >
              <Row>
                <Col
                  md={2}
                  style={{
                    height: '160px',
                  }}
                >
                  <Skeleton variant="rectangular" width="100%" height="100%" />
                </Col>
                <Col
                  md={10}
                  style={{
                    display: 'grid',
                  }}
                >
                  <Skeleton variant="rectangular" width="100%" height="15px" />
                  <Skeleton variant="rectangular" width="100%" height="15px" />
                  <Skeleton variant="rectangular" width="80%" height="15px" />
                  <Skeleton variant="rectangular" width="70%" height="15px" />
                  <Skeleton variant="rectangular" width="70%" height="15px" />
                  <Skeleton variant="rectangular" width="70%" height="15px" />
                </Col>
              </Row>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default CardGrid;
