import { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

export const NotificationsContext = createContext();

function NotificationsProvider(props) {
  const [notifications, setNotifications] = useState([]);

  function notify(message, type = 'info') {
    const id = uuid();

    function dismiss(id) {
      clearTimeout(notifications.find((notification) => notification.id === id));
      setNotifications((prev) => prev.filter((notification) => notification.id !== id));
    }

    const timeout = setTimeout(() => {
      dismiss(id);
    }, props.timeout);

    setNotifications((prev) => prev.concat({ id, message, type, timeout }));
  }
  return <NotificationsContext.Provider value={{ notifications, notify }}>{props.children}</NotificationsContext.Provider>;
}

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  timeout: PropTypes.number,
};
NotificationsProvider.defaultProps = { timeout: 3000 };

export default NotificationsProvider;
