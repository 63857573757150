export type IContacto = {
  id: number;
  mensaje: string;
  email: string;
  telefono: string;
  fecha: Date;
  nombre: string;
  visto: boolean;
  destacado: boolean;
};
export enum propiedadType {
  Local = 'Local',
  Oficina = 'Oficina',
  Galpon = 'Galpon',
  Terreno = 'Terreno',
  Casa = 'Casa',
  PH = 'PH',
  Quinta = 'Quinta',
  Departamento = 'Departamento',
  Edificio = 'Edificio',
  FondoDeComercio = 'Fondo de Comercio',
}

export enum propiedadOperation {
  Venta = 'Venta',
  Alquiler = 'Alquiler',
  AlquilerTemporal = 'Alquiler temporal',
  FondoDeComercio = 'Fondo de comercio',
}

export type IPropiedad = {
  id?: number;
  tipo: string;
  ambientes: number;
  barrio: string;
  lngLat: string;
  baños: number;
  direccion: string;
  capacidad: string;
  operacion: string;
  superficie: number;
  subtipo: string;
  caracteristicas: string[];
  otros: string[];
  imagenes: {
    imageBlob: string;
  }[];
  publicadoEnZp: boolean;
  activo: boolean;
  destacado: boolean;
  paginaPrincipal: boolean;
  fechaDeInicio?: Date;
  fechaFinal?: Date;
  valorMes: number;
  valorSemana: number;
  expensas: number;
  valorVenta: number;
  enDolares: boolean;
  estadiaMinima: number;
  descripcion: string;
  garantia: string;
  titulo: string;
  deleted: boolean;
  createdAt?: Date;
  calle: string;
  valor?: number;
  cochera?: boolean;
  gas?: boolean;
  trifasica?: boolean;
  vidriera?: boolean;
  entrada?: boolean;
};
