import {
  faDog,
  faExpand,
  faExpandAlt,
  faPerson,
  faPersonBooth,
  faWifi,
  faCar,
  faKitchenSet,
  faCoffee,
  faBreadSlice,
} from '@fortawesome/free-solid-svg-icons';

export const detalleMap = {
  capacidad: {
    icon: faPerson,
  },
  superficie: {
    suffix: 'm2',
    icon: faExpand,
  },
  superficieCubierta: {
    suffix: 'm2',
    icon: faExpandAlt,
  },
  habitaciones: {
    icon: faPersonBooth,
  },
  wifi: {
    icon: faWifi,
  },
  mascotas: {
    icon: faDog,
  },
  cochera: {
    icon: faCar,
  },
  cocina: {
    icon: faKitchenSet,
  },
  cafetera: {
    icon: faCoffee,
  },
  tostadora: {
    icon: faBreadSlice,
  },
};

export const detalleArray = [
  'tipo',
  'ambientes',
  'superficie',
  'superficieCubierta',
  'vista',
  'balcon',
  'cochera',
  'capacidad',
  'baños',
  'gas',
  'trifasica',
  'entrada',
  'vidriera',
  'zonificacion',
  'expensas',
  'wifi',
  'cocina',
  'cafetera',
  'tostadora',
  'freezer',
  'horno',
  'heladera',
  'microondas',
  'lavarropas',
  'laundry',
  'parrilla',
  'calefaccionCentral',
  'mascotas',
  'piscina',
  'economico',
  'mediosDeTransporte',
];
