import axios from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

export function handleLogout() {
  localStorage.removeItem('jwtToken');
  delete axios.defaults.headers.common['Authorization'];
}

export async function apiCall(url: string, method: 'GET' | 'PUT' | 'POST' | 'DELETE', data?: any) {
  const token = localStorage.getItem('jwtToken');

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.get['cache-control'] = 'max-age=31536000';

    const decodedToken: {
      exp: number;
    } = jwtDecode(token);

    if (decodedToken && decodedToken.exp < moment().unix()) {
      handleLogout();
      return null;
    }
  }
  const host = typeof window !== 'undefined' && window.location.origin;

  const response = await axios({
    method,
    url: `${host}${url}`,
    data,
  });

  if (response.status === 403 || response.status === 401) {
    handleLogout();

    return null;
  }

  return response.data;
}

export async function verifySession(token: string) {
  return apiCall(`/verify`, 'POST', {
    token,
  });
}
