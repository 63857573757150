import { Skeleton } from '@mui/material';
import { Col, Row } from 'react-bootstrap';

const DetallePropiedadLoading = () => {
  return (
    <Row
      style={{
        margin: '20px',
      }}
    >
      <Col md={8}>
        <Skeleton
          style={{
            width: '100%',
            height: '300px',
          }}
          variant="rectangular"
        />
        <Skeleton
          style={{
            width: '100%',
            height: '40px',
          }}
          variant="text"
        />
        <Skeleton
          style={{
            width: '80%',
            height: '40px',
          }}
          variant="text"
        />
        <Skeleton
          style={{
            width: '100%',
            height: '40px',
          }}
          variant="text"
        />
        <Skeleton
          style={{
            width: '80%',
            height: '40px',
          }}
          variant="text"
        />
      </Col>
      <Col md={4}>
        <Skeleton
          style={{
            width: '100%',
            height: '300px',
          }}
          variant="rectangular"
        />
        <Skeleton
          style={{
            width: '50%',
            height: '40px',
          }}
          variant="text"
        />
        <Skeleton
          style={{
            width: '100%',
            height: '40px',
          }}
          variant="text"
        />
        <Skeleton
          style={{
            width: '100%',
            height: '40px',
          }}
          variant="text"
        />
        <Skeleton
          style={{
            width: '80%',
            height: '40px',
          }}
          variant="text"
        />
      </Col>
    </Row>
  );
};

export default DetallePropiedadLoading;
