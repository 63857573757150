/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from 'react';
import './Admin.scss';
import { Container } from 'react-bootstrap';
import CardGrid from '../../components/CardGrid/CardGrid';
import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import FormPropiedades from '../../components/FormPropiedades/FormPropiedades';
import { apiCall } from '../../shared/service';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Button } from '@material-ui/core';
import PaginationComponent from '../../components/CardGrid/PaginationComponent';
import PropiedadFilter from '../../components/AdminFilter/PropiedadFilter';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Table from './Table';

const Admin = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const [propiedades, setPropiedades] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [propiedadesPerPage, setPropiedadesPerPage] = useState(10);
  const [operacion, setOperacion] = useState<null | Array<any>>([]);
  const [tipo, setTipo] = useState<null | Array<any>>([]);
  const [precio, setPrecio] = useState<null | Array<any>>([]);
  const [estado, setEstado] = useState<null | Array<any>>([]);
  const [fecha, setFecha] = useState<null | string>(null);
  const [titulo, setTitulo] = useState<null | string>();
  const [count, setCount] = useState(0);
  const history = useNavigate();
  const [searchParams] = useSearchParams();
  const [query, setQuery] = useState('');

  const searchQuery = useMemo(() => {
    let search = '';

    for (const entry of searchParams.entries()) {
      search += `${entry[0]}=${entry[1]}&`;
    }
    return search;
  }, [searchParams]);

  const fetchPropiedades = useCallback(async () => {
    setLoading(true);

    apiCall(`/propiedad?${searchQuery}`, 'GET').then((res: any) => {
      setPropiedades(res.propiedades);
      setCount(res.count);
      setLoading(false);
    });
  }, [searchQuery]);

  useEffect(() => {
    fetchPropiedades();
  }, [query]);

  useEffect(() => {
    let query = `?amount=1000`;

    if (estado?.length) {
      if (estado.includes('Destacado')) {
        query += `&destacado=${true}`;
      }
      if (estado.includes('Página Principal')) {
        query += `&paginaPrincipal=${true}`;
      }
    }
    if (tipo?.length) {
      query += `&tipo=${tipo}`;
    }
    if (operacion?.length) {
      query += `&operacion=${operacion}`;
    }
    if (fecha?.length) {
      query += `&fecha=${fecha}`;
    }
    if (titulo) {
      query += `&id=${parseInt(titulo)}`;
    }
    if (precio?.length) {
      if (precio.includes('USD')) {
        query += `&enDolares=${true}`;
      }
    }

    setQuery(query);
    history(`/admin${query}`);
  }, [estado, tipo, operacion, precio, fecha, titulo, currentPage, propiedadesPerPage]);

  return (
    <Container>
      <div className="card-grid">
        <PropiedadFilter
          setOperacion={setOperacion}
          setTipo={setTipo}
          setPrecio={setPrecio}
          precio={precio}
          tipo={tipo}
          operacion={operacion}
          estado={estado}
          setEstado={setEstado}
          fecha={fecha}
          setFecha={setFecha}
          titulo={titulo}
          setTitulo={setTitulo}
        />
        <ScrollAnimation offset={100} duration={1} className="pagination-grid" animateIn={'animate__fadeInDown'} animateOnce={true}>
          <Button color="primary" variant="contained" className="button-agregar" onClick={toggleOpen}>
            Agregar propiedad
          </Button>
        </ScrollAnimation>
        {/*<PaginationComponent
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          propiedadesPerPage={propiedadesPerPage}
          totalPropiedades={count}
          setPropiedadesPerPage={setPropiedadesPerPage}
        />
         <div className="grid-container">
          <CardGrid
            loading={loading}
            admin={true}
            shownProp={propiedades}
            currentPage={currentPage}
            propiedadesPerPage={propiedadesPerPage}
            fetchPropiedades={fetchPropiedades}
          />
        </div> <PaginationComponent
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          propiedadesPerPage={propiedadesPerPage}
          totalPropiedades={count}
          setPropiedadesPerPage={setPropiedadesPerPage}
        />*/}
        <Table propiedades={propiedades} refetch={() => fetchPropiedades()} loading={loading} />
        <Modal open={open} onClose={() => toggleOpen()} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
          <div>
            <FormPropiedades
              onClose={(refetch) => {
                if (refetch) {
                  fetchPropiedades();
                }
                toggleOpen();
              }}
            />
          </div>
        </Modal>
      </div>
    </Container>
  );
};

export default Admin;
