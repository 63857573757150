import './ModalGallery.scss';
import Carousel from 'react-bootstrap/Carousel';
import { useState } from 'react';

const ModalGallery = (props) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="gallery" id="gallery">
      <Carousel interval={null} activeIndex={index} onSelect={handleSelect}>
        {props.imagenes.map((x, ix) => (
          <Carousel.Item key={ix}>
            <img src={x.imageBlob} alt="propiedad"/>
          </Carousel.Item>
        ))}
      </Carousel>
      <div>
        <div className="all-images">
          {props.imagenes.map((x, ix) => (
            <div onClick={() => handleSelect(ix)} className="img-min" key={ix}>
              <img src={x.imageBlob} alt="propiedad" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModalGallery;
