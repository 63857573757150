import { IPropiedad } from '../../shared/types';
import defaultImage from '../../assets/default.jpeg';
import './CartaPropiedad.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import IconUbi from '../../assets/icons/location.png';
import MtsIcon from '../../assets/icons/mts2.png';

const MinimalCartaPropiedad = (props: IPropiedad) => {
  const openPropiedad = () => {
    window.open(`/propiedades/${props.id}`, '_blank');
  };

  return (
    <ScrollAnimation offset={-1} duration={1} animateIn={'animate__fadeIn'} animateOnce={true}>
      <div
        className="minimal-card"
        onClick={openPropiedad}
        onMouseDown={(e) => {
          if (e.button === 1) {
            openPropiedad();
          }
        }}
      >
        <div className="banner-container">
          <div className="banner">
            {props.tipo} en {props.operacion}
          </div>
        </div>
        <div className="property-thumbnail">
          <img className="minimal-img-top" alt={props.titulo ||'propiedad'} src={props.imagenes?.length ? props.imagenes?.[0]?.imageBlob : defaultImage} />
          <div className="minimal-detail">
            <div className="container-price">
              <p className="minimal-price">
                {props.enDolares ? 'u$s' : '$'}{' '}
                {props.valor && props.valor > 0 ? (props.valor ?? props.valorMes ?? 'Consultar').toLocaleString('es-AR') : 'Consultar'}
              </p>
              {/* <p className="code-card">COD ID {props.id}</p> */}
            </div>
            {props.calle && (
              <div className="details-vivienda" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <img src={IconUbi} width={20} height={20} alt="icon" />
                <p className="minimal-location text-truncate">{props.calle}</p>
              </div>
            )}
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4, color: '#555' }}>
              <img src={MtsIcon} width={20} height={20} alt="icon" />
              <p className="metros-card">
                {props.superficie?.toLocaleString('es-AR')} m<sup>2</sup> <span>{props.barrio && props.barrio}</span>
              </p>
            </div>
            {/* {props.barrio && (
              <div className="details-vivienda" style={{ display: 'flex', flexDirection: 'row', marginLeft: '23px', alignItems: 'center' }}>
                <p className="minimal-location">{props.barrio}</p>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </ScrollAnimation>
  );
};

export default MinimalCartaPropiedad;
