import './SobreNosotros.scss';
import image from '../../assets/madero.jpg';

function About() {
  return (
    <>
      <section className="about-container">
        <div className="about-rsp">
          <img className="image-about" width={600} height={300} src={image} alt="puerto-madero" />
          <article className="text-about">
            <h4 className="title-about">Sobre Nosotros</h4>
            <p className="description-about">
              Seky Propiedades, es una inmobiliaria comercial ubicada en el centro geográfico de la Ciudad de Buenos Aires que ofrece: Inmuebles
              Industriales - Alquiler y Venta de Depósitos, Galpones y Edificios Industriales ubicados principalmente en la Zona Centro y Sur de la
              Capital Federal. Inmuebles Comerciales: Comercialización de Locales, venta de terrenos y alquileres de oficinas. Inmuebles
              Residenciales: Alquiler y venta de departamentos en la Ciudad de Buenos Aires. Servicio de Tasaciones judiciales y privadas con el aval
              de martilleros especializados.
            </p>
          </article>
        </div>
      </section>
    </>
  );
}

export default About;
