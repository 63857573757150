import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import Logo from '../../assets/logo-new.png';
import './Navbar.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { handleLogout } from '../../shared/service';
import phone from '../../assets/phone-fill.svg';
// import { Badge } from '@mui/material';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

const host = typeof window !== 'undefined' && window.location.origin;

const NavbarComponent = ({ loggedIn, setUser }: { loggedIn?: boolean; setUser?: ({ auth, name }: { auth: boolean; name: string }) => void }) => {
  const logout = () => {
    axios
      .get(`${host}/logout`)
      .then(() => {
        setUser?.({ auth: false, name: '' });
        handleLogout();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Navbar className="navBar" variant="dark" expand="lg">
      <Container className="navbar-container">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <ScrollAnimation offset={0} duration={1} animateIn={'animate__fadeInDown'} animateOnce={true}>
          <Navbar.Brand href="/">
            <img alt="Logo" width={190} height={40} src={Logo} className="logo" />
          </Navbar.Brand>
        </ScrollAnimation>
        <ScrollAnimation offset={0} duration={1} animateIn={'animate__fadeInDown'} animateOnce={true}>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="nav-links">
              <Link style={{ textDecoration: 'none' }} to="/propiedades?amount=10&tipo=Galpon">
                Galpones
              </Link>
              <Link style={{ textDecoration: 'none' }} to="/propiedades?amount=10&tipo=Local">
                Locales
              </Link>
              <Link style={{ textDecoration: 'none' }} to="/propiedades?amount=10&tipo=Terreno">
                Terrenos
              </Link>
              <Link style={{ textDecoration: 'none' }} to="/propiedades?amount=10&tipo=Vivienda">
                Viviendas
              </Link>
              <Link style={{ textDecoration: 'none' }} to="/contacto">
                Contacto
              </Link>
            </Nav>
          </Navbar.Collapse>
        </ScrollAnimation>
        <ScrollAnimation offset={0} duration={1} animateIn={'animate__fadeInDown'} animateOnce={true} /* className="outer-logout-btn" */>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <a style={{ textDecoration: 'none' }} href="tel:+54 11 49028965">
              <div
                className="phone-nav"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#ededed',
                  borderRadius: '8px',
                  width: '175px',
                  color: '#2160bd',
                  fontWeight: 600,
                  padding: '5px 12px',
                }}
              >
                <img src={phone} width={20} height={20} alt="phone" /> +54 11 49028965
              </div>
            </a>
            {loggedIn && (
              <FontAwesomeIcon title="Salir" color="#fff" style={{ cursor: 'pointer' }} onClick={logout} icon={faRightFromBracket as IconProp} />
            )}
          </div>
        </ScrollAnimation>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
