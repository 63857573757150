/* eslint-disable max-len */
import './Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faCaretRight, faLocationDot, faPhone, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import FaceIcon from '../../assets/icons/facebook-circle-fill.svg';
import InstaIcon from '../../assets/icons/instagram-fill.svg';
import YoutIcon from '../../assets/icons/youtube-fill.svg';
import Logo from '../../assets/logo-new.png';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const history = useNavigate();
  const fecha = new Date();
  const year = fecha.getFullYear();
  return (
    <footer className="footer">
      <div className="footer-info">
        <div className="footer-right ">
          <img onClick={() => history('/')} width={180} height={55} className="logo" src={Logo} alt="logo" />
          <div className="redes-icons">
            <a href="https://m.facebook.com/sekyprop" target="_blank" rel="noopener noreferrer">
              <img src={FaceIcon} width={35} height={35} alt="icon" />
            </a>
            <img src={InstaIcon} width={35} height={35} alt="icon" />
            <img src={YoutIcon} width={35} height={35} alt="icon" />
          </div>
        </div>
        <span
          className="separador-foot"
          style={{ width: '95%', height: '1px', backgroundColor: ' #c6c6c6', margin: 'auto', marginTop: '15px' }}
        ></span>
        <div>
          <div>
            <p className="icon-text">
              <FontAwesomeIcon className="icon-footer" icon={faLocationDot as IconProp} />
              <a
                target="_blank"
                rel={'noopener noreferrer'}
                href="https://www.google.com.ar/maps/place/Av.+Acoyte+110,+C1405BFO+CABA/@-34.6170122,-58.4395752,17z/data=!3m1!4b1!4m5!3m4!1s0x95bcca40e8a42399:0x9d682766e476fad8!8m2!3d-34.6170166!4d-58.4373865"
              >
                <span> Acoyte 110 Local 88 </span> Buenos Aires, CABA
              </a>
            </p>
          </div>
          <div>
            <p className="icon-text">
              <FontAwesomeIcon className="icon-footer" width={20} height={20} icon={faPhone as IconProp} />
              <a href="tel:+541149028965">+54 11 4902 8965 </a>
            </p>
          </div>
          <div>
            <p className="icon-text">
              <FontAwesomeIcon className="icon-footer" width={20} height={20} icon={faWhatsapp as IconProp} />
              <a href="https://api.whatsapp.com/send?phone=541153334258&">+54 11 5333 4258 </a>
            </p>
          </div>
          <div>
            <p className="icon-text">
              <FontAwesomeIcon className="icon-footer" width={20} height={20} icon={faPaperPlane as IconProp} />
              <a href="tel:+541149028965">+54 11 5333 4258 </a>
            </p>
          </div>
          <div>
            <p className="icon-text">
              <FontAwesomeIcon className="icon-footer" width={20} height={20} icon={faEnvelope as IconProp} />
              <a href="mailto:info@seky.com.ar">info@seky.com.ar</a>
            </p>
          </div>
        </div>
        <span
          className="separador-foot"
          style={{ width: '95%', height: '1px', backgroundColor: ' #c6c6c6', margin: 'auto', marginTop: '15px' }}
        ></span>
        <div>
          <ul className="contact-list">
            <li className="list-contact">
              <FontAwesomeIcon icon={faCaretRight as IconProp} />
              <a href="/contacto">Contacto</a>
            </li>
            <li className="list-contact">
              <FontAwesomeIcon icon={faCaretRight as IconProp} />
              <a href="/contacto">Ofrecer inmuebles</a>
            </li>
            <li className="list-contact">
              <FontAwesomeIcon icon={faCaretRight as IconProp} />
              <a href="/contacto">Solicitar Tasaciòn</a>
            </li>
            <li className="list-contact">
              <FontAwesomeIcon icon={faCaretRight as IconProp} />
              <a href="/propiedades">Inmuebles en venta y alquiler</a>
            </li>
          </ul>
        </div>
      </div>
      <span style={{ width: '95%', height: '1px', backgroundColor: ' #c6c6c6', margin: 'auto', marginTop: '15px' }}></span>
      <div className="copy">
        <p>SekyPropiedades © {year}</p>
      </div>
    </footer>
  );
};

export default Footer;
