import TextField from 'material-ui-formik-components/TextField/TextField';
import Select from 'material-ui-formik-components/Select/Select';
import Switch from 'material-ui-formik-components/Switch/Switch';

export const fields: {
  [key: string]: {
    label: string;
    component: any;
    options?: any;
    tipo?: string[];
    name?: string;
    default: any;
    type?: string;
    placeholder?: string;
    required?: boolean;
    multiline?: boolean;
    minRows?: number;
    maxRows?: number;
    style?: any;
  };
} = {
  titulo: {
    tipo: ['Galpon', 'Local', 'Oficina', 'Terreno', 'Vivienda', 'Alquiler'],
    name: 'titulo',
    label: 'Título',
    default: '',
    component: TextField,
  },
  tipo: {
    tipo: ['Local', 'Oficina', 'Galpon', 'Terreno', 'Vivienda', 'Alquiler'],
    name: 'tipo',
    default: 'Galpon',
    label: 'Tipo',
    component: Select,
    options: [
      { value: 'Local', label: 'Local' },
      { value: 'Oficina', label: 'Oficina' },
      { value: 'Galpon', label: 'Galpon' },
      { value: 'Terreno', label: 'Terreno' },
      { value: 'Vivienda', label: 'Vivienda' },
    ],
  },
  ambientes: {
    tipo: ['Vivienda', 'Alquiler'],
    name: 'ambientes',
    label: 'Ambientes',
    default: '',
    component: TextField,
    type: 'number',
  },
  barrio: {
    tipo: ['Galpon', 'Local', 'Oficina', 'Vivienda', 'Alquiler'],
    name: 'barrio',
    default: '',
    label: 'Barrio',
    component: TextField,
  },
  direccion: {
    tipo: ['Galpon', 'Local', 'Oficina', 'Vivienda', 'Alquiler', 'Terreno'],
    name: 'direccion',
    label: 'Dirección (Completa para mapa)',
    default: '',
    placeholder: 'Calle, numero, barrio, ciudad, pais',
    component: TextField,
  },
  calle: {
    tipo: ['Galpon', 'Local', 'Oficina', 'Vivienda', 'Alquiler'],
    name: 'calle',
    label: 'Calles (Info Publica)',
    default: '',
    component: TextField,
  },
  capacidad: {
    tipo: ['Vivienda'],
    name: 'capacidad',
    label: 'Capacidad',
    default: '',
    component: TextField,
    type: 'number',
  },
  operacion: {
    tipo: ['Local', 'Oficina', 'Galpon', 'Terreno', 'Vivienda'],
    name: 'operacion',
    default: 'Venta',
    label: 'Operación',
    component: Select,
    options: [
      { value: 'Alquiler', label: 'Alquiler' },
      { value: 'Venta', label: 'Venta' },
      { value: 'Alquiler temporal', label: 'Alquiler temporal' },
      { value: 'Fondo de comercio', label: 'Fondo de comercio' },
    ],
  },
  superficie: {
    tipo: ['Galpon', 'Local', 'Oficina', 'Terreno', 'Vivienda', 'Alquiler'],
    name: 'superficie',
    label: 'Superficie',
    default: '',
    component: TextField,
    type: 'number',
  },
  superficieCubierta: {
    tipo: ['Galpon', 'Local', 'Oficina', 'Terreno', 'Vivienda', 'Alquiler'],
    name: 'superficieCubierta',
    label: 'Superficie Cubierta',
    default: '',
    component: TextField,
    type: 'number',
  },
  /* caracteristicas: {
    tipo: ['Local', 'Oficina', 'Galpon', 'Terreno', 'Vivienda', 'Alquiler'],
    name: 'caracteristicas',
    label: 'Características (Separar por comas)',
    default: '',
    component: TextField,
    multiline: true,
  },
  otros: {
    tipo: ['Local', 'Oficina', 'Galpon', 'Terreno', 'Vivienda', 'Alquiler'],
    name: 'otros',
    label: 'Otros (Separar por comas)',
    default: '',
    component: TextField,
    multiline: true,
  }, */
  valorMes: {
    tipo: ['Vivienda'],
    name: 'valorMes',
    label: 'Valor Mensual',
    default: '',
    component: TextField,
    type: 'number',
  },
  baños: {
    tipo: ['Galpon', 'Local', 'Oficina', 'Vivienda', 'Alquiler'],
    name: 'baños',
    label: 'Baños',
    default: '',
    component: Select,
    options: [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
    ],
  },
  subtipo: {
    tipo: ['Vivienda', 'Alquiler'],
    name: 'subtipo',
    label: 'Subtipo',
    default: '',
    component: Select,
    options: [
      { value: 'casa', label: 'Casa' },
      { value: 'ph', label: 'PH' },
      { value: 'depto', label: 'Departamento' },
      { value: 'quinta', label: 'Quinta' },
    ],
  },
  subtipo_local: {
    tipo: ['Local'],
    name: 'subtipo_local',
    label: 'Subtipo',
    default: '',
    component: Select,
    options: [
      { value: 'local', label: 'Local' },
      { value: 'fondo', label: 'Fondo de comercio' },
    ],
  },
  expensas: {
    tipo: ['Vivienda', 'Alquiler'],
    name: 'expensas',
    label: 'Valor Expensas',
    default: '',
    component: TextField,
    type: 'number',
  },
  estadiaMinima: {
    tipo: ['Vivienda'],
    name: 'estadiaMinima',
    label: 'Estadía Mínima',
    default: '',
    component: TextField,
    type: 'number',
  },
  valor: {
    tipo: ['Galpon', 'Local', 'Oficina', 'Terreno', 'Vivienda'],
    name: 'valor',
    label: 'Valor',
    default: '',
    component: TextField,
    type: 'number',
  },
  zonificacion: {
    tipo: ['Galpon'],
    name: 'zonificacion',
    label: 'Zonificación',
    default: '',
    component: TextField,
  },
  vista: {
    tipo: ['Vivienda'],
    name: 'vista',
    label: 'Vista',
    default: '',
    component: TextField,
  },
  mediosDeTransporte: {
    tipo: ['Vivienda'],
    name: 'mediosDeTransporte',
    label: 'Medios de transporte',
    default: '',
    component: TextField,
  },
  descripcion: {
    tipo: ['Galpon', 'Local', 'Oficina', 'Terreno', 'Vivienda', 'Alquiler'],
    name: 'descripcion',
    label: 'Descripción',
    default: '',
    minRows: 3,
    maxRows: 6,
    multiline: true,
    style: {
      width: '94%',
    },
    component: TextField,
  },
  paginaPrincipal: {
    tipo: ['Galpon', 'Local', 'Oficina', 'Terreno', 'Vivienda', 'Alquiler'],
    name: 'paginaPrincipal',
    label: 'Página Principal',
    default: false,
    component: Switch,
  },
  destacado: {
    tipo: ['Galpon', 'Local', 'Oficina', 'Terreno', 'Vivienda', 'Alquiler'],
    name: 'destacado',
    label: 'Destacado',
    default: false,
    component: Switch,
  },
  activo: {
    tipo: ['Galpon', 'Local', 'Oficina', 'Terreno', 'Vivienda', 'Alquiler'],
    name: 'activo',
    label: 'Activo',
    default: true,
    component: Switch,
  },
  enDolares: {
    tipo: ['Galpon', 'Local', 'Oficina', 'Terreno', 'Vivienda', 'Alquiler'],
    name: 'enDolares',
    label: 'Precio en USD',
    default: false,
    component: Switch,
  },
  mascotas: {
    tipo: ['Vivienda'],
    name: 'mascotas',
    label: 'Mascotas',
    default: false,
    component: Switch,
  },
  piscina: {
    tipo: ['Vivienda'],
    name: 'piscina',
    label: 'Piscina',
    default: false,
    component: Switch,
  },
  cochera: {
    tipo: ['Vivienda'],
    name: 'cochera',
    label: 'Cochera',
    default: false,
    component: Switch,
  },
  economico: {
    tipo: ['Vivienda'],
    name: 'economico',
    label: 'Economico',
    default: false,
    component: Switch,
  },
  wifi: {
    tipo: ['Vivienda'],
    name: 'wifi',
    label: 'WiFi',
    default: false,
    component: Switch,
  },
  balcon: {
    tipo: ['Vivienda'],
    name: 'balcon',
    label: 'Balcón',
    default: false,
    component: Switch,
  },
  lavarropas: {
    tipo: ['Vivienda'],
    name: 'lavarropas',
    label: 'Lavarropas',
    default: false,
    component: Switch,
  },
  gas: {
    tipo: ['Galpon', 'Local', 'Oficina', 'Vivienda'],
    name: 'gas',
    label: 'Gas',
    default: false,
    component: Switch,
  },
  trifasica: {
    tipo: ['Galpon', 'Local', 'Oficina', 'Vivienda'],
    name: 'trifasica',
    label: 'Trifasica',
    default: false,
    component: Switch,
  },
  vidriera: {
    tipo: ['Galpon', 'Local', 'Oficina', 'Vivienda'],
    name: 'vidriera',
    label: 'Vidriera',
    default: false,
    component: Switch,
  },
  laundry: {
    tipo: ['Vivienda'],
    name: 'laundry',
    label: 'Laundry',
    default: false,
    component: Switch,
  },
  parrilla: {
    tipo: ['Vivienda'],
    name: 'parrilla',
    label: 'Parrilla',
    default: false,
    component: Switch,
  },
  calefaccionCentral: {
    tipo: ['Vivienda'],
    name: 'calefaccionCentral',
    label: 'Calefaccion central',
    default: false,
    component: Switch,
  },
  cafetera: {
    tipo: ['Vivienda'],
    name: 'cafetera',
    label: 'Cafetera',
    default: false,
    component: Switch,
  },
  tostadora: {
    tipo: ['Vivienda'],
    name: 'tostadora',
    label: 'Tostadora',
    default: false,
    component: Switch,
  },
  freezer: {
    tipo: ['Vivienda'],
    name: 'freezer',
    label: 'Freezer',
    default: false,
    component: Switch,
  },
  horno: {
    tipo: ['Vivienda'],
    name: 'horno',
    label: 'Horno',
    default: false,
    component: Switch,
  },
  entrada: {
    tipo: ['Vivienda', 'Galpon', 'Local'],
    name: 'entrada',
    label: 'Entrada de vehiculo',
    default: false,
    component: Switch,
  },
  heladera: {
    tipo: ['Vivienda'],
    name: 'heladera',
    label: 'Heladera',
    default: false,
    component: Switch,
  },
  cocina: {
    tipo: ['Vivienda'],
    name: 'cocina',
    label: 'Cocina',
    default: false,
    component: Switch,
  },
  microondas: {
    tipo: ['Vivienda'],
    name: 'microondas',
    label: 'Microondas',
    default: false,
    component: Switch,
  },
};

export default fields;
