import { useContext } from 'react';
import { NotificationsContext } from './NotificationsProvider';

export default function useNotify() {
  const { notify } = useContext(NotificationsContext);

  return {
    success(message) {
      notify(message, 'success');
    },

    error(message) {
      notify(message, 'danger');
    },

    info(message) {
      notify(message, 'info');
    },
  };
}
