import { Formik, Field, Form, FormikHelpers } from 'formik';
import { IPropiedad } from '../../shared/types';
import './FormPropiedades.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fields } from './FormFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Button from '@mui/material/Button';
import { apiCall } from '../../shared/service';
import useNotify from '../../shared/useNotify';

type FormType = {
  defaultValues?: IPropiedad;
  onClose: (refetch?: boolean) => void;
};

const FormPropiedades = (props: FormType) => {
  const [images, setImages] = useState<Array<string>>([]);
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setImages(props?.defaultValues?.imagenes?.map((imagen) => imagen.imageBlob) ?? []);
  }, []);

  const uploadImage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e?.target?.files?.[0];
      const reader = new FileReader();

      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (reader.result?.toString()) {
            const newImages = [...images, reader.result.toString()];

            setImages(newImages);
          }
        };
      }
    },
    [images],
  );

  const deleteImage = (index: number) => {
    const newImages = [...images];

    newImages.splice(index, 1);
    setImages(newImages);
  };

  const initialValues = useMemo(() => {
    if (props.defaultValues) {
      return { ...props.defaultValues, caracteristicas: props.defaultValues?.caracteristicas, otros: props.defaultValues?.otros };
    } else {
      return Object.keys(fields).reduce((acc, key) => {
        const value = fields[key as keyof typeof fields].default;

        acc[key as keyof typeof fields] = value;
        return acc;
      }, {} as any);
    }
  }, [props.defaultValues]);

  return (
    <div
      className="propiedad_form"
      style={
        loading
          ? {
              background: 'transparent',
              border: 'none',
              overflow: 'hidden',
            }
          : {}
      }
    >
      {loading ? (
        <FontAwesomeIcon
          icon={faSpinner as IconProp}
          spin
          style={{
            fontSize: '50px',
            margin: 'auto',
            width: '100%',
          }}
        />
      ) : (
        <>
          <div className="card-title">
            {props.defaultValues ? (
              <>
                <h1>{props.defaultValues.titulo}</h1>
                <h3>{props.defaultValues.direccion}</h3>
              </>
            ) : (
              <h1>Crear propiedad</h1>
            )}
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={(values: IPropiedad, { setSubmitting }: FormikHelpers<IPropiedad>) => {
              setLoading(true);
              const data = { ...values };

              Object.keys(values).forEach((key) => {
                if (!fields[key as keyof typeof fields]?.tipo?.includes(values?.tipo)) {
                  delete data[key as keyof typeof fields];
                }
              });

              const GeoCoder = new (window as any).google.maps.Geocoder();
              GeoCoder.geocode({ address: data.direccion }, (result) => {
                const [googleResponse] = result;
                const { geometry } = googleResponse;
                const { location } = geometry;

                data.lngLat = `${location?.lng()},${location?.lat()}`;

                if (props.defaultValues) {
                  apiCall(`/propiedad/${props.defaultValues.id}`, 'PUT', { ...data, imagenes: images }).then(() => {
                    notify.info('Propiedad actualizada');
                    props.onClose(true);
                  });
                } else {
                  apiCall(`/propiedad`, 'POST', { ...data, imagenes: images }).then(() => {
                    notify.info('Propiedad creada');
                    props.onClose(true);
                  });
                }
                setSubmitting(false);
              });
            }}
          >
            {({ values }) => (
              <Form aria-disabled={loading} className="fields-container">
                {Object.keys(fields)
                  .filter((key) => fields[key as keyof typeof fields].tipo?.includes(values.tipo))
                  .map((x) => {
                    const defaultProp: any = {};

                    return <Field {...defaultProp} className="fields" key={x} {...fields[x as keyof typeof fields]} />;
                  })}
                <br />
                <div className="images-container">
                  <Button className="add-image-btn" variant="contained" component="label">
                    <FontAwesomeIcon icon={faImage as IconProp} />
                    <input
                      onChange={(e) => {
                        uploadImage(e);
                        e.target.value = '';
                      }}
                      type="file"
                      hidden
                    />
                  </Button>
                  {images.map((image, index) => {
                    return (
                      <div className="image-container" key={`image-${index}`}>
                        <img src={image} alt="image" />
                        <FontAwesomeIcon className="delete-btn" onClick={() => deleteImage(index)} icon={faTrash as IconProp} />
                      </div>
                    );
                  })}
                </div>
                <br />
                <div className="button-container">
                  <Button variant="outlined" className="button" onClick={() => props.onClose()}>
                    Cancelar
                  </Button>
                  <Button type="submit" variant="contained" className="button">
                    {props.defaultValues ? 'Editar' : 'Crear'}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export default FormPropiedades;
